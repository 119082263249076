import { GoodsCategoriesValueItem } from './goodsCategories.interface';
/**
 * utils
 **/
/* mark onChange return data  */
export const markReturnDataStructure = (value: GoodsCategoriesValueItem) => {
  return {
    categoryName: value.categoryName || '',
    categoryNo: value.categoryNo || '-1',
    parentNo: value.parentNo || '0',
    categoryNoPath: value.categoryNoPath || '',
    hasChild: value.hasChild || 0,
    level: value.level || 1,
    isLeaf: value.isLeaf || 0,
    icon: value.icon || '',
  };
};
