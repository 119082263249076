import { Modal, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import React from 'react';
import ReactDOM from 'react-dom';
import { YModalProps } from './modal.interface';
import { destroyFns, styleSize, wrapWithClose, calcBodyStyle } from './util';
export function useModal() {
  let currentConfig = { visible: false } as YModalProps;
  let containerDiv: any;
  let container: any;
  if (typeof window !== 'undefined') {
    containerDiv = document.createElement('div');
    container = document.body;
  }
  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(containerDiv);
    if (unmountResult && containerDiv.parentNode) {
      containerDiv.parentNode.removeChild(containerDiv);
    }
    if (currentConfig.onCancel) {
      currentConfig.onCancel();
    }
    for (let i = 0; i < destroyFns.length; i++) {
      const fn = destroyFns[i];
      if (fn === hide) {
        destroyFns.splice(i, 1);
        break;
      }
    }
  }
  function render({
    title,
    size,
    okText,
    cancelText,
    prefixCls,
    onOk,
    onCancel,
    content,
    visible,
    footer,
    bodyStyle,
    ...props
  }: YModalProps) {
    ReactDOM.render(
      <ConfigProvider locale={zhCN}>
        <Modal
          title={title}
          okText={okText || '确定'}
          cancelText={cancelText || '取消'}
          keyboard={false}
          centered={true}
          maskClosable={false}
          width={styleSize(size).width}
          getContainer={container}
          destroyOnClose
          {...props}
          bodyStyle={{
            ...calcBodyStyle(size, title, footer),
            ...bodyStyle,
          }}
          visible={visible}
          footer={footer}
          onOk={() => {
            wrapWithClose(onOk);
          }}
          onCancel={() => {
            hide();
          }}
        >
          {typeof content === 'function' ? content() : content}
        </Modal>
      </ConfigProvider>,
      containerDiv,
    );
  }
  // 更新 部分参数
  function update(newConfig: YModalProps) {
    currentConfig = {
      ...currentConfig,
      ...newConfig,
      visible: true,
    };
    render(currentConfig);
  }

  function show(newConfig: YModalProps) {
    currentConfig = {
      ...newConfig,
      visible: true,
    };
    destroyFns.push(hide);
    render(currentConfig);
  }

  function hide() {
    currentConfig = {
      ...currentConfig,
      visible: false,
      afterClose: destroy,
    };
    render(currentConfig);
  }

  return {
    show,
    hide,
    update,
  };
}

export const ModalHookDestroyAll = function destroyAllFn() {
  // console.log('destroyAllFn', destroyFns)
  while (destroyFns.length) {
    const close = destroyFns.pop();
    if (close) {
      close();
    }
  }
};
