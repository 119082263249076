import React, { useEffect, useState, useRef } from 'react';
import { Table } from 'antd';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import { YTableProps } from './table.interface';
import { useModel } from '../filter/useModel';
import { TablePaginationConfig } from 'antd/lib/table';

export const YTable: React.FC<YTableProps> = (props) => {
  const tableRef = useRef(null);
  let filterFetchData: any;
  const [columns, setColumns] = useState<any>([]);
  const { isNeedPlaceholder, onReload, store, request, filters, pagination, scroll, apiMap, tableParams, ...tableProps } = props;
  if (typeof store === 'function') {
    filterFetchData = useModel().filterFetchData;
  }
  // 如果是嵌入在filter里面，透传过来的数据
  const [tableInfo] = store ? store() : [];
  // 当前页
  const [pageIndex, setPageIndex] = useState(tableParams?.pageIndex || (pagination && pagination?.current) || 1);
  const [pageSize, setPageSize] = useState(tableParams?.pageSize || (pagination && pagination?.pageSize) || 10);
  const [scrollData, setScrollData] = useState({ x: 'max-content' });
  // 不管是table自身请求还是filter透传数据，都赋值给renderData
  const [renderData, setRenderData] = useState<any>({});


  useEffect(() => {
    init();
  }, [tableInfo]);

  useEffect(() => {
    setScrollConfig();
  }, []);

  // 如果是嵌入在filter组件内使用
  if (tableInfo) {
    useEffect(() => {
      setPageIndex(1);
    }, [tableInfo.isReset]);
  }

  // 如果没有传递rowKey。默认加一个
  const setKey = (arr?: any[]) => {
    if (!props.rowKey) {
      arr && arr.length && arr.forEach((item: any) => {
        item._key = uuidv4();
      });
    }
  };

  const init = async () => {
    if (!store && !tableProps.dataSource && request) {
      getList();
    } else if (store) {
      setKey(tableInfo?.[apiMap?.data || 'data']?.[apiMap?.list || 'list']);
      setRenderData(tableInfo);
    }
  };

  // 获取table数据
  const getList = async (params?: { pageIndex: number | string, pageSize: number | string, [propsName: string]: any }) => {
    try {
      setRenderData({ ...renderData, isLoading: true });
      const resData: any = await (request as any)((pagination !== false ? {
        pageIndex,
        pageSize, ...params,
      } : { ...params }));
      setKey(resData?.[apiMap?.list || 'list']);
      setRenderData({ ...renderData, data: resData, isLoading: false });
    } catch (e) {
      console.log(e);
    }
  };

  const _onReload = (bool?: boolean) => {
    if (bool) {
      getList({ pageIndex: 1, pageSize });
      setPageIndex(1);
    } else {
      getList()
    }
  }
  if (onReload) {
    onReload(_onReload);
  }

  // 分页事件
  const pageChange = async (_pageIndex: number, _pageSize: number) => {
    // 嵌在filter的情况
    if (store && tableInfo) {
      await filterFetchData({
        pageIndex: _pageIndex,
        pageSize: _pageSize,
      });
      //没有嵌在filter，table含有request的情况
    } else if (request) {
      getList({ pageIndex: _pageIndex, pageSize: _pageSize });
    }
    setPageIndex(_pageIndex);
    setPageSize(_pageSize);
  };

  // 设置分页信息
  const paginationData = () => {
    if (pagination ?? {}) {
      return {
        total: renderData?.data?.[apiMap?.totalNumber || 'totalNumber'] || 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total: number) => `共 ${total} 条记录`,
        onChange: (page: number, pageSize: number) => pageChange(page, pageSize),
        ...pagination,
        current: pageIndex,
        pageSize: pageSize,
      };
    } else {
      return pagination;
    }
  };

  // 设置scroll
  const setScrollConfig = () => {
    // 所有用户定义宽的列的宽度
    let allCustomColumnWidth = 0;
    // 未定义的列宽的个数
    let allNoWidthNum = 0;
    props.columns && props.columns.forEach((item) => {
      allCustomColumnWidth += parseInt((item.width || 0).toString(), 10);
      !item.width && (allNoWidthNum += 1);
      isNeedPlaceholder && (item.render = item.render || ((item) => <>{`${item || '-'}`}</>));
    });
    setColumns(props.columns);
    if (!scroll) {
      // 兼容windows下滚动条宽度的问题，所以减去20
      const tableWidth = (tableRef.current as any).clientWidth - 20;
      // 未定义列宽，默认最小宽度100
      const allColumnWith = allCustomColumnWidth + allNoWidthNum * 100;
      setScrollData({ x: `${(allColumnWith > tableWidth) ? allColumnWith : tableWidth - 1}px` });
    } else {
      setScrollData(scroll as any);
    }
  };

  return <div ref={tableRef}>
    <Table
      rowKey={`${props.rowKey || '_key'}`}
      dataSource={renderData?.[apiMap?.data || 'data']?.[apiMap?.list || 'list'] || []}
      loading={!!renderData?.isLoading}
      {...tableProps}
      columns={isNeedPlaceholder ? columns : props.columns}
      pagination={paginationData() as TablePaginationConfig}
      tableLayout="fixed"
      scroll={scrollData}
      bordered
    />
  </div>;
};

