import React from 'react';
import { YModal } from '../../modal';
import { YUploadFile } from '../common/interface';

interface ImageModalProps {
  fileList: YUploadFile[];
  getRef: (params: any) => any;
}

export const ImgModal: React.FC<ImageModalProps> = (props) => {
  const { fileList, getRef } = props;
  const [imgIndex, setIndex] = React.useState(-1);
  const imgFileList = fileList.filter((item: YUploadFile) => item.fileType === 'image');
  const show = (index: number) => setIndex(index);
  
  getRef && getRef({ show })

  return (
    <YModal.Image
      visible={imgIndex > -1}
      images={imgFileList.map(item => ({src: item.url}))}
      index={Math.max(imgIndex, 0)}
      close={() => setIndex(-1)}
    />
  )
}