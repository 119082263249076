import React, {useRef} from 'react';
import { Form, Input, Button } from 'antd';
import {YAuthProps} from './layout.interface';
import {useModel} from './useModel';

export const Login : React.FC<YAuthProps> = ({onSubmit}) => {
  const { login } = useModel();
  const nameRef = useRef(null);
  const onFinish = async (values: any) => {
    const res = await login({
        ...values,
        projectId: 'PROAUTH'
    });
    if (res?.userToken) {
        onSubmit && onSubmit();
        if(typeof window === 'undefined'){

        } else {
          window.localStorage.setItem('token', res.userToken);
          window.location.href='/';
        }
        

    }
  }
  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Form.Item
        label="账号"
        name="userPhone"
        rules={[{ required: true, message: '请输入用户账号!' }]}
      >
        <Input maxLength={20} ref={nameRef} />
      </Form.Item>

      <Form.Item
        label="密码"
        name="userPasswd"
        rules={[{ required: true, message: '请输入账号密码!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" block>登录</Button>
      </Form.Item>
      <Form.Item>
        <a href="/restPassword">重置密码</a>
      </Form.Item>

    </Form>
  )
}