import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { YGoodsCategoriesProps } from './goodsCategories.interface';
import {
  DEF_ALL_TYPE_NUM,
  DEF_ALL_TYPE_TEXT,
  DEF_PARENTNO,
} from './goodsCategories.config';
import { markReturnDataStructure } from './goodsCategories.utils';
import { useModel } from './useModel';

const { Option } = Select;

export const GoodsCategoriesSinge: React.FC<YGoodsCategoriesProps<any>> = (
  props,
) => {
  const { config, onChange, originalProps } = props;
  const { getCategoryList } = useModel();
  const parentNo = config.parentNo ? config.parentNo : DEF_PARENTNO;
  const [selectItemList, setSelectItemList] = useState([]);

  /* get CatList Data */
  const getCatListData = async (parentNo: string) => {
    const res: any = await getCategoryList({
      parentNo: parentNo,
    });
    if (config.isShowAll) {
      res.unshift({
        categoryNo: DEF_ALL_TYPE_NUM,
        categoryName: config.selectAllText
          ? config.selectAllText
          : DEF_ALL_TYPE_TEXT,
      });
    }
    setSelectItemList(res);
  };

  /* Actions */
  /* 支持名称模糊搜索 */
  const selectFilter = (input: any, option: any) => {
    if (option && option.props && option.props.title) {
      return (
        option.props.title === input || option.props.title.indexOf(input) !== -1
      );
    } else {
      return true;
    }
  };

  /* selected Item Callback */
  const selectOnChange = (value: any) => {
    let selectedValues = [];
    if (Array.isArray(value)) {
      selectedValues = value;
    } else {
      selectedValues.push(value);
    }
    let retData: any = null;
    if (config.multiple) {
      retData = [];
      for (let i = 0; i < selectedValues.length; i++) {
        retData.push(markReturnDataStructure(JSON.parse(selectedValues[i])));
      }
    } else {
      retData = markReturnDataStructure(JSON.parse(value));
    }
    onChange(retData);
  };

  useEffect(() => {
    getCatListData(parentNo);
  }, []);

  return (
    <>
      <Select
        {...(originalProps as SelectProps<any>)}
        onChange={selectOnChange}
        optionFilterProp="children"
        filterOption={selectFilter}
        showSearch
        mode={config.multiple ? 'multiple' : undefined}
      >
        {selectItemList &&
          selectItemList.map((item: any) => {
            return (
              <Option
                key={item.categoryNo}
                value={JSON.stringify(item)}
                title={item.categoryName}
              >
                {item.categoryName}
              </Option>
            );
          })}
      </Select>
    </>
  );
};
