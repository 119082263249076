import styled from 'styled-components';
import { WrapStyleProps } from './interface';

export const WrapStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  ${(props: WrapStyleProps) =>
    props.uploadType === 'uploadPicture' && props.column
      ? `
    width: ${104 * props.column + 8 * (props.column - 1)}px;
  `
      : ``};
  ${(props: WrapStyleProps) =>
    props.uploadType === 'uploadPicture' &&
    !(props.disabled && props.hidePreview)
      ? `
    min-height: 112px;
  `
      : `
    flex-wrap: wrap-reverse;
  `};
  .ant-upload-picture-card-wrapper {
    width: auto;
  }
  .ant-upload-list-picture-card-container {
    width: auto;
    height: auto;
    margin: 0;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin-right: 0;
  }

  .YUpload-upload-button {
    cursor: pointer;
  }

  .YUpload-text-upload-wrap {
    width: 100%;
  }

  .YUpload-list-preview-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;
