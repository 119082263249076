
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PRO_XY_LIVE";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 25 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}



export interface AuthUserInfoInput {
  
}

export interface AuthUserInfoData {
  
    
      // 
      userToken: string;
    
      // 
      userId: string;
    
      // 
      userName: string;
    
  
}

// 1995 获取用户信息

  export const useGetAuthUserInfo = () => {
    let isMount = true;
    const [isAuthUserInfoLoading, setIsAuthUserInfoLoading] = useState(false);
    const [dataAuthUserInfo, setDataAuthUserInfo] = useState<AuthUserInfoData>({} as AuthUserInfoData);
    const getAuthUserInfoData = async( config?: AxiosRequestConfig): Promise<AuthUserInfoData> => {
      if(isMount){
        setIsAuthUserInfoLoading(true);
      }
      
      try{
        
          
          const res: any = await Request.get<AuthUserInfoData>(`${basePath}/auth/user/info`, config);
          
          
          
          if(isMount){
            setDataAuthUserInfo(res || {});
            setIsAuthUserInfoLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsAuthUserInfoLoading(false);
        }
        return false as unknown as AuthUserInfoData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isAuthUserInfoLoading,
      dataAuthUserInfo,
      getAuthUserInfoData,
    }
  }

