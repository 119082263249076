export const configs: Record<string, any> = {
  PROSUPPLIER: {
    mock: {
      API_BASE_URL: 'http://yapi.ywfe.com/mock',
      APPLICATION_URL: 'http://supply-manage-debug.fed.ywwl.com',
    },
    dev: {
      API_BASE_URL: 'http://dev-api-srm-supplier.ywwl.com',
      APPLICATION_URL: 'http://supply-manage-debug.fed.ywwl.com',
    },
    pre: {
      API_BASE_URL: 'https://api-srm-supplier.ywwl.com',
      APPLICATION_URL: 'https://srm-manage.ywwl.com',
    },
    test: {
      API_BASE_URL: 'http://test-api-srm-supplier.ywwl.com',
      APPLICATION_URL: 'http://supplyht.test.ywwl.com',
    },
    prod: {
      API_BASE_URL: 'https://api-srm-supplier.ywwl.com',
      APPLICATION_URL: 'https://srm-manage.ywwl.com',
    },
  },
  PROAUTH: {
    mock: {
      API_BASE_URL: 'http://yapi.ywfe.com/mock',
      APPLICATION_URL: 'http://supply-manage-debug.fed.ywwl.com',
    },
    dev: {
      API_BASE_URL: 'https://dev-api-auth.ywwl.com',
      APPLICATION_URL: 'http://supply-manage-debug.fed.ywwl.com',
    },
    pre: {
      API_BASE_URL: 'https://api-auth.ywwl.com',
      APPLICATION_URL: 'http://supply-manage-debug.fed.ywwl.com',
    },
    test: {
      API_BASE_URL: 'https://test-api-auth.ywwl.com',
      APPLICATION_URL: 'http://supplyht.test.ywwl.com',
    },
    prod: {
      API_BASE_URL: 'https://api-auth.ywwl.com',
      APPLICATION_URL: 'https://srm-manage.ywwl.com',
    },
  },
  PROGOODS: {
    mock: { API_BASE_URL: 'http://yapi.ywfe.com/mock', APPLICATION_URL: '' },
    dev: {
      API_BASE_URL: 'http://dev-api-item-center.ywwl.com',
      APPLICATION_URL: '',
    },
    pre: {
      API_BASE_URL: 'https://api-item-center.ywwl.com',
      APPLICATION_URL: 'https://framework.ywwl.com',
    },
    test: {
      API_BASE_URL: 'http://goods-new.test.ywwl.com/goods_center',
      APPLICATION_URL: 'http://framework.test.ywwl.com',
    },
    prod: {
      API_BASE_URL: 'https://api-item-center.ywwl.com',
      APPLICATION_URL: 'https://framework.ywwl.com',
    },
  },
  PRO_XY_LIVE: {
    mock: { API_BASE_URL: 'http://yapi.ywfe.com/mock', APPLICATION_URL: '' },
    dev: {
      API_BASE_URL: 'http://dev-api-zhibo.ywwl.com',
      APPLICATION_URL: '',
    },
    pre: {
      API_BASE_URL: 'https://api-zhibo.ywwl.com',
      APPLICATION_URL: 'https://framework.ywwl.com',
    },
    test: {
      API_BASE_URL: 'https://test-api-zhibo.ywwl.com',
      APPLICATION_URL: 'http://framework.test.ywwl.com',
    },
    prod: {
      API_BASE_URL: 'https://api-zhibo.ywwl.com',
      APPLICATION_URL: 'https://framework.ywwl.com',
    },
  },
  COMMON: {
    mock: { API_BASE_URL: 'http://yapi.ywfe.com/mock', APPLICATION_URL: '' },
    dev: {
      API_BASE_URL: 'https://dev-generic-api.ywwl.com',
      APPLICATION_URL: '',
    },
    pre: {
      API_BASE_URL: 'https://dev-generic-api.ywwl.com',
      APPLICATION_URL: 'http://pl.fed.ywwl.com',
    },
    test: {
      API_BASE_URL: 'https://test-generic-api.ywwl.com',
      APPLICATION_URL: 'http://framework.test.ywwl.com',
    },
    prod: {
      API_BASE_URL: 'https://generic-api.ywwl.com',
      APPLICATION_URL: 'https://framework.ywwl.com',
    },
  },
  DING_TALK: {
    mock: {
      APPLICATION_URL: 'https://oapi.dingtalk.com',
      APP_ID: 'dingoalin2gwh8gkrln214',
      TOKEN: 'kWX8bwwG6uC6igB2lUAB0StnRho2ayu3wW_YRlY194xmeLB69MEOfC7mMwqxc_ns',
    },
    dev: {
      APPLICATION_URL: 'https://oapi.dingtalk.com',
      APP_ID: 'dingoalin2gwh8gkrln214',
      TOKEN: 'kWX8bwwG6uC6igB2lUAB0StnRho2ayu3wW_YRlY194xmeLB69MEOfC7mMwqxc_ns',
    },
    pre: {
      APPLICATION_URL: 'https://oapi.dingtalk.com',
      APP_ID: 'dingoalin2gwh8gkrln214',
      TOKEN: 'kWX8bwwG6uC6igB2lUAB0StnRho2ayu3wW_YRlY194xmeLB69MEOfC7mMwqxc_ns',
    },
    test: {
      APPLICATION_URL: 'https://oapi.dingtalk.com',
      APP_ID: 'dingoamci9opuonpnbfi05',
      TOKEN: 'kWX8bwwG6uC6igB2lUAB0StnRho2ayu3wW_YRlY194xmeLB69MEOfC7mMwqxc_ns',
    },
    prod: {
      APPLICATION_URL: 'https://yun.ywwl.com/login',
      APP_ID: 'dingoal2pwz2md6zj26rsn',
      TOKEN: 'kWX8bwwG6uC6igB2lUAB0StnRho2ayu3wW_YRlY194xmeLB69MEOfC7mMwqxc_ns',
    },
  },
};
