import { useGetNewPartnerList, NewPartnerListData } from '../generated/api';

export const useModel = () => {
  const { getNewPartnerListData } = useGetNewPartnerList();
  // 获取合作模式数据
  const getPartnerList = async (): Promise<NewPartnerListData> => {
    const res: any = await getNewPartnerListData();
    return res || [];
  };

  return {
    getPartnerList,
  };
};
