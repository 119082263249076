// 默认配置
export const DEFAULT_ORIGIN_CONFIG: Record<
  string,
  string | boolean | object
> = {
  showSearch: false,
};

// 默认配置
export const DEFAULT_CONFIG: Record<
  string,
  object | string | boolean | number
> = {
  showAddBtn: false,
  valueType: 'string',
  showDefaultOption: false,
  shouldFetchWhenEmpty: false,
  defaultOptionText: '请选择',
  defaultOptionValue: '',
  previewPlaceholder: '-',
  requestTime: 1000,
  apiDataKey: 'list',
  minWidth: '200px',
  hoverText: false,
  hoverTextConfig: {},
  shouldRequestOnFilter: false,
};

// 远程获取时配置
export const QUERY_ORIGIN_CONFIG: Record<string, string | boolean | object> = {
  placeholder: '请输入关键字',
  filterOption: false,
  defaultActiveFirstOption: false,
};

// 可自增项配置
export const ADD_ORIGIN_CONFIG: Record<string, string | boolean | object> = {
  filterOption: false,
  defaultActiveFirstOption: false,
};

// 可自增项配置
export const ADD_CUSTOM_CONFIG: Record<string, string | boolean | object> = {
  canAddItem: true,
  addBtnText: '作为新选项增加',
};
