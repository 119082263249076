import React from 'react';
import {Tabs} from 'antd';
import {Login} from './auth.login';
import { ResetPassword} from './auth.resetPassword';
import {LoginScan} from './auth.loginScan';
import {YAuthProps} from './layout.interface';
import {useModel} from './useModel';
import {LoginStyle, FormStyle, LoginLogoStyle, FormBoxStyle} from './styles';
import loginLogo from './login-logo.png';

const videoPath = "http://pl.fed.ywwl.com/static/media/video.0a2a5219.mp4";
const { TabPane } = Tabs;

export const YAuth : React.FC<YAuthProps> = ({type, onSubmit}) => {
  const { login, switchAuthType } = useModel();
  const switchType = (key: string) => {
    if(key === 'loginByPassword'){
      switchAuthType('login');
    }
  }
  return (
    <LoginStyle>
      <video
        autoPlay={true}
        loop
        preload="true"
        muted
        playsInline={true}
        src={videoPath}
        className="video">
        <source src={videoPath} type="video/mp4"></source>
        <source src={videoPath} type="audio/ogg"></source>
        <source src={videoPath} type="audio/mpeg"></source>
      </video>
      <FormStyle>
        <FormBoxStyle>
          <LoginLogoStyle src={loginLogo} />
          {
            type === "login" && (
              <Tabs defaultActiveKey="loginByPassword" onChange={switchType}>
                <TabPane tab="账号密码登录" key="loginByPassword">
                  <Login onSubmit={onSubmit} />
                </TabPane>
                <TabPane tab="扫码登录" key="loginByScan">
                  <LoginScan />
                </TabPane>
              </Tabs>
            )
          }
          {
            type === "reset password" && <ResetPassword />
          }
        </FormBoxStyle>
      </FormStyle>
    </LoginStyle>
  )
}