import React, { useState, useEffect } from 'react';
import { Checkbox, message } from 'antd';
import { ValueType, YCheckboxProps } from './checkbox.interface';
import { useModel } from "./useModel";
import { CheckboxLabel } from './styles'

export const YCheckbox: React.FC<YCheckboxProps> = (props) => {

  const { config, value, onChange } = props
  const { label, options, separator, originalProps, maxChoice, toast, show, request } = config
  
  const [checkboxData, setCheckboxData] = useState({
    label: label || '',
    value: value || [],
    options: options || []
  });
  const { getPartnerList } = useModel();

  // 变更值
  const triggerChange = (changedValue: Array<ValueType>) => {
    if (maxChoice && changedValue.length > maxChoice) {
      message.error(toast || `最多选择${maxChoice}项，请先取消选择`)
      return
    }
    
    setCheckboxData({
      ...checkboxData,
      value: changedValue
    })

    if (show) show(changedValue)
    if (onChange) onChange(changedValue);
  };
  // 初始化
  const initFetchData = async () => {
    // 固定配置项
    if (options) {
      setCheckboxData({
        label: label || '',
        value: value || [],
        options: options,
      })
      if (show) show(checkboxData.value)
      return
    }
    let res :any
    // 接口
    if (request) {
      res = await request({})
      setCheckboxData({
        label: label || '',
        value: value || [],
        options: res || [],
      })
      if (show) show(checkboxData.value)
      return
    }
    // 全都没有，默认合作模式
    res = await getPartnerList()
    setCheckboxData({
      label: label || '合作模式',
      value: value || res.map((item: any) => (item.checked ? item.type : '')).filter((item: number | string) => item),
      options: res.map((item: any) => ({label: item.name, value: item.type })),
    })
    if (show) show(checkboxData.value)
  }

  useEffect(() => {
    initFetchData()
  }, [label, options, value]);
  
  return (
    <div style={props.style || {}}>
      {checkboxData.label && (
        <CheckboxLabel>{checkboxData.label}{separator || ':'} </CheckboxLabel>
      )}
      <Checkbox.Group
        {...(originalProps || {})}
        options={checkboxData.options}
        value={checkboxData.value || []}
        onChange={triggerChange}
      />
    </div>
  )

}