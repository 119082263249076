
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROGOODS";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 85 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}



export interface AreaListInput {
  
    
      /** 查询类型 1:省 2:市 3:区 4:街道 */
      type: string;
    
  
    
      /** 编号 type为1时可不传，其余传上级编号 */
      code?: string;
    
  
}

export interface AreaListData {
  
}

// 10365 省市区街道四级联动

  export const useGetAreaList = () => {
    let isMount = true;
    const [isAreaListLoading, setIsAreaListLoading] = useState(false);
    const [dataAreaList, setDataAreaList] = useState<AreaListData>({} as AreaListData);
    const getAreaListData = async(params: AreaListInput, config?: AxiosRequestConfig): Promise<AreaListData> => {
      if(isMount){
        setIsAreaListLoading(true);
      }
      
      try{
        
          
          const res: any = await Request.get<AreaListData>(`${basePath}/area/list?${querystring.stringify(params as unknown as ParsedUrlQueryInput)}`, config);
          
          
          
          if(isMount){
            setDataAreaList(res || {});
            setIsAreaListLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsAreaListLoading(false);
        }
        return false as unknown as AreaListData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isAreaListLoading,
      dataAreaList,
      getAreaListData,
    }
  }

