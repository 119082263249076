
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROAUTH";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 88 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}


  
    
      export interface AuthUserMenuItem {
        
          // 资源父id
          resourceParentId?: number;
        
          // 菜单名称
          name?: string;
        
          // icon
          icon?: string;
        
          // 跳转url
          url?: string;
        
          // 排序
          sort?: number;
        
          // 菜单层级
          level?: number;
        
          // 1启用 0禁用
          status?: string;
        
          // 备注
          remark?: string;
        
          // ResourceMenuDto
          childMenus?: undefined[];
        
          // 菜单id
          resourceId?: string;
        
          // MENU ACTION BUTTON
          resourceType?: string;
        
          // PUBLISH PRIVATE
          isPublish?: string;
        
      }
    
  


export interface AuthUserMenuInput {
  
    
      /** 项目id */
      projectId: string;
    
  
}

export interface AuthUserMenuData {
  
    
      // ResourceMenuDto
      list?: AuthUserMenuItem[];
    
      // 总数
      totalNumber?: string;
    
  
}

// 18788 获取菜单接口

  export const useGetAuthUserMenu = () => {
    let isMount = true;
    const [isAuthUserMenuLoading, setIsAuthUserMenuLoading] = useState(false);
    const [dataAuthUserMenu, setDataAuthUserMenu] = useState<AuthUserMenuData>({} as AuthUserMenuData);
    const getAuthUserMenuData = async(params: AuthUserMenuInput, config?: AxiosRequestConfig): Promise<AuthUserMenuData> => {
      if(isMount){
        setIsAuthUserMenuLoading(true);
      }
      
      try{
        
          
          const res: any = await Request.get<AuthUserMenuData>(`${basePath}/auth/user/menu?${querystring.stringify(params as unknown as ParsedUrlQueryInput)}`, config);
          
          
          
          if(isMount){
            setDataAuthUserMenu(res || {});
            setIsAuthUserMenuLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsAuthUserMenuLoading(false);
        }
        return false as unknown as AuthUserMenuData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isAuthUserMenuLoading,
      dataAuthUserMenu,
      getAuthUserMenuData,
    }
  }

