import { useFilterContext } from './filter.context';
import { YFilterState, DefalutTableParams } from './filter.interface';
import CONFIG_CONST from './filter.config';

export const useModel = () => {
  const {
    store,
    request,
    filters,
    defaultTableParams,
    onBeforeRequest,
    onAfterRequest,
  } = useFilterContext();

  const [, dispatch] = store();

  /**
   * 更新数据
   * @param params 参数
   * @param callback   更新之后
   */
  const filterFetchData = async (
    params: {
      pageIndex?: number | string;
      pageSize?: number | string;
    },
    callback?: () => void,
  ) => {
    typeof onBeforeRequest === 'function' && onBeforeRequest();
    dispatch({
      type: 'fetch_api_data',
      payload: {
        data: {
          isLoading: true,
          // 把数据更新到仓库里面，场景我切换到一页30个时，点击Filter查询，
          _data: {
            _pageIndex: params.pageIndex || CONFIG_CONST.PAGE_INDEX,
            _pageSize: params.pageSize || CONFIG_CONST.PAGE_SIZE,
          },
        },
      },
    });

    if (typeof params === 'undefined') {
      params = {
        pageIndex: CONFIG_CONST.PAGE_INDEX,
        pageSize: CONFIG_CONST.PAGE_SIZE,
      };
    }

    const tableParams: DefalutTableParams = {};
    //@ts-ignore
    tableParams[
      defaultTableParams?.apiPageIndex || CONFIG_CONST.API_PAGE_INDEX
    ] = params.pageIndex;
    //@ts-ignore
    tableParams[defaultTableParams?.apiPageSize || CONFIG_CONST.API_PAGE_SIZE] =
      params.pageSize;

    let filterData: any;
    let resultData: YFilterState | null = null;

    try {
      filterData = await request({ ...filters, ...tableParams });
      typeof callback === 'function' && callback();
      resultData = {
        isLoading: false,
        data: filterData,
        error: {
          isError: false,
          message: null,
        },
      };
    } catch (err) {
      console.log('传入filter内部请求函数异常', err);
      resultData = {
        isLoading: false,
        data: filterData,
        error: {
          isError: true,
          message: err,
        },
      };
    } finally {
      typeof onAfterRequest === 'function' && onAfterRequest(filterData);

      dispatch({
        type: 'fetch_api_data',
        payload: {
          data: resultData,
        },
      });
    }
  };

  return {
    filterFetchData,
  };
};
