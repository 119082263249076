import { useEffect, useRef } from "react";

export const useSetInterval = (callback: Function, delay: number) =>{
  if (!(callback instanceof Function)) {
    throw new Error("callback 参数必须是函数！");
  }
  if (!(delay === null || typeof delay === "number")) {
    throw new Error("delay 必须是 null 或者数字！");
  }
  let savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return;
    }
    let timer: number;
    const tick = () => {
      const returnValue = savedCallback.current && savedCallback.current() as Function;
      if (returnValue) {
        if (returnValue instanceof Function) {
          returnValue();
        } else {
          throw new Error("返回值必须是函数！");
        }
        clearTimeout(timer);
        return;
      }
      timer = setTimeout(tick, delay);
    };
    timer = setTimeout(tick, delay);
    return () => clearInterval(timer);
  }, [delay]);
}
