import styled from 'styled-components';
import { ImageStyleProps } from './interface';

export const ImageStyle = styled.img`
  max-width: calc(100% - 10px);
  max-height: calc(100% / ${(props: ImageStyleProps) => props.length} - 10px);
  display: block;
  margin: 5px;
  cursor: pointer;
`;
