import styled from 'styled-components';

export const TitleStyle = styled.div`
  padding: 10px 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
`;

interface WrapProps {
  width?: number;
}

export const GlobalStyle = styled.div`
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
`;

export const EllipsisStyle = styled.span`
  display: inline-block;
  width: ${(props: WrapProps) => props.width + 'px'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
`;
