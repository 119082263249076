
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROGOODS";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 181 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}


  
    
      export interface CategorySpuListItem {
        
          // 类目编号
          categoryNo: string;
        
          // 类目名称
          categoryName: string;
        
          // 类目编号路径
          categoryNoPath: string;
        
          // 父类目名称
          parentNo: string;
        
          // 是否有子节点：0-否 1-是
          hasChild: number;
        
          // 是否是叶子节点：0-否，1-是
          isLeaf: number;
        
          // 数量统计
          total: number;
        
      }
    
  


export interface CategorySpuListInput {
  
    
      /** 类目父节点编号（根节点传0） */
      parentNo: string;
    
  
}

export interface CategorySpuListData {
  
    
      // 
      list: CategorySpuListItem[];
    
  
}

// 18526 [类目管理] - SPU类目树查询

  export const useGetCategorySpuList = () => {
    let isMount = true;
    const [isCategorySpuListLoading, setIsCategorySpuListLoading] = useState(false);
    const [dataCategorySpuList, setDataCategorySpuList] = useState<CategorySpuListData>({} as CategorySpuListData);
    const getCategorySpuListData = async(params: CategorySpuListInput, config?: AxiosRequestConfig): Promise<CategorySpuListData> => {
      if(isMount){
        setIsCategorySpuListLoading(true);
      }
      
      try{
        
          
          const res: any = await Request.get<CategorySpuListData>(`${basePath}/category/spu/list?${querystring.stringify(params as unknown as ParsedUrlQueryInput)}`, config);
          
          
          
          if(isMount){
            setDataCategorySpuList(res || {});
            setIsCategorySpuListLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsCategorySpuListLoading(false);
        }
        return false as unknown as CategorySpuListData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isCategorySpuListLoading,
      dataCategorySpuList,
      getCategorySpuListData,
    }
  }

