
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROAUTH";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 88 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}



export interface UserLoginInput {
  
    
    /** undefined */
    userPhone?: string;
  
    
    /** undefined */
    userPasswd?: string;
  
    
    /** PROAUTH:权限中心 */
    projectId?: string;
  
}

export interface UserLoginData {
  
    
      // 
      bizUserId: string;
    
      // 
      createdAt: string;
    
      // 
      id: number;
    
      // 
      isOut: string;
    
      // 
      status: string;
    
      // 
      updatedAt: string;
    
      // 
      userEmail: string;
    
      // 
      userId: string;
    
      // 
      userName: string;
    
      // 
      userPasswd: string;
    
      // 
      userPhone: string;
    
      // 
      userToken: string;
    
  
}

// 11935 用户登录

  export const usePostUserLogin = () => {
    let isMount = true;
    const [isUserLoginLoading, setIsUserLoginLoading] = useState(false);
    const [dataUserLogin, setDataUserLogin] = useState<UserLoginData>({} as UserLoginData);
    const postUserLoginData = async(params: UserLoginInput, config?: AxiosRequestConfig): Promise<UserLoginData> => {
      if(isMount){
        setIsUserLoginLoading(true);
      }
      
      try{
        
          
          
          const res: any = await Request.post<UserLoginData>(`${basePath}/user/login`, params, config);
          
          
          if(isMount){
            setDataUserLogin(res || {});
            setIsUserLoginLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsUserLoginLoading(false);
        }
        return false as unknown as UserLoginData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isUserLoginLoading,
      dataUserLogin,
      postUserLoginData,
    }
  }

