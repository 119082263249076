import { createSubscriptionHook } from '@ywfe/hook-subscription';
import {
  ApplicationAction,
  ApplicationState,
  YRouterItem,
} from './application.interface';

const initialState: ApplicationState = {
  menus: (null as unknown) as YRouterItem[],
  defaultSelectedMenus: [],
  defaultOpenMenus: [],
  selectedMenu: [],
  openMenu: [],
  permissions: [],
  pageName: '',
  pageData: {},
  isInit: false,
};

const reducer = (
  state: ApplicationState,
  action: ApplicationAction,
): ApplicationState => {
  switch (action.type) {
    case 'init_menu': {
      return {
        ...state,
        ...action.payload.data,
        isInit: true,
      };
    }
    case 'name_page': {
      return {
        ...state,
        ...action.payload.data,
        isInit: false,
      };
    }
    case 'set_each_data': {
      return {
        ...state,
        pageData: {
          ...state.pageData,
          ...{ [state.pageName]: action.payload.data.pageData },
        },
        isInit: false,
      };
    }
    case 'set_all_data': {
      return {
        ...state,
        pageData: {
          ...state.pageData,
          ...action.payload.data.pageData,
        },
        isInit: false,
      };
    }
    default: {
      return state;
    }
  }
};
export const useApplicationStore = createSubscriptionHook(
  reducer,
  initialState,
);
