import React, {useState} from 'react';
import {Form, Input, Button, Row, Col} from 'antd';
import {isChinaMobile} from '@ywfe/utils';
import {YAuthProps} from './layout.interface';
import {useSetInterval} from '../hooks';
import {useModel} from './useModel';
import {CodeBtnStyle} from './styles';

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};

export const ResetPassword: React.FC<YAuthProps> = ({onSubmit}) => {
  const {sendCode, updatePasswordByMtCode} = useModel();
  const [disabled, setDisabled] = useState(false);
  const [second, setSecond] = useState(60);
  const [delay, setDelay] = useState<number>(null as unknown as number);
  const [form] = Form.useForm();

  useSetInterval(() => {
    if (second <= 0) {
      return () => {
        setDelay(null as unknown as number);
        setSecond(60);
        setDisabled(false);
      };
    }
    setSecond(second - 1);
  }, delay);

  const toSendCode = async () => {
    try {
      const values = await form.validateFields(['telephone']);
      const res = await sendCode({
        telephone: values.telephone,
        codeType: 'RESET_PWD',
      });
      if(res){
        setDisabled(true);
        setDelay(1000);
      }
    } catch (error) {
      const {errorFields} = error;
    } 
    
  }

  const onFinish = async (values: any) => {
    console.log('values', values)
    const res = await updatePasswordByMtCode({
      telephone: values.telephone,
      passWd: values.passWd,
      mtCode: values.mtCode
    });
    if (res) {
        onSubmit && onSubmit();
      }  

    }

    const onTest = () => {
      const values = form.getFieldsValue();
      console.log('onTest values', values)
    }

  return (
    <Form form={form} onFinish={onFinish} {...formItemLayout}>
      <Form.Item
        name="telephone"
        label="手机号码"
        rules={[{ 
          validator: (_, value) => isChinaMobile(value) ? Promise.resolve() : Promise.reject('请输入正确的手机号码！')
          }]}
      >
        <Input  maxLength={20} />
      </Form.Item>
      <Form.Item label="验证码">
        <Row gutter={2}>
          <Form.Item
            name="mtCode"
            rules={[{ required: true, message: '请输入验证码!' }]}
            noStyle
          >
            <Input style={{ width: 90 }} maxLength={6} />
          </Form.Item>
          <CodeBtnStyle type="primary" disabled={disabled} onClick={toSendCode}>
            {second === 60 ? '发送验证码' : `${second}秒后发送`}
          </CodeBtnStyle>
        </Row>
      </Form.Item>
      <Form.Item
        name="passWd"
        label="新密码"
        rules={[{ required: true, message: '请输入新密码!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="userPhone"
        label="确认新密码"
        rules={[{ required: true, message: '请输入新密码!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Button size="large" type="primary" htmlType="submit" block>
        重置密码
      </Button>
    </Form>
  );
};
