import { useState } from 'react';
import { useGetOssGetgeneratepresignedurl } from '../../generated/api';
import { YModal } from '../../modal';
import { YUploadFile } from '../common/interface';
import { getQuery } from '../common/utils';
import { ChangeType } from '../upload.interface';
import { PreviewItemProps } from './interface';

export const useModel = (props: PreviewItemProps) => {
  const {
    imgModalRef,
    fileModalRef,
    projectId,
    expire,
    securityType,
    fileList,
    fileDisabled,
    showFileModel,
    setFileList,
  } = props;
  const {
    getOssGetgeneratepresignedurlData,
  } = useGetOssGetgeneratepresignedurl();
  const [loadingStatus, setLoadingStatus] = useState(0); // 0: 成功 1: 进行中 2: 失败
  const [previewUrl, setPreviewUrl] = useState('');
  // 获取预览接口
  const getOSSUrlAPI = async (file: YUploadFile, allIndex: number) => {
    const { url, ossFile } = file;
    if (!ossFile) return;
    if (url) {
      const nowTime = new Date().valueOf();
      const expireTimeStr = getQuery('Expires', url);
      const expireTime = Number(expireTimeStr || 0) * 1000;
      if (!expireTimeStr || expireTime > nowTime) return;
    }

    setLoadingStatus(1);

    const params: { [name: string]: any } = {
      projectId: projectId || '',
      ossFile,
    };
    if (expire) params.expire = expire;

    try {
      const res: any = await getOssGetgeneratepresignedurlData(params as any);
      if (!res || !res.url) {
        setLoadingStatus(2);
        return;
      }
      setLoadingStatus(0);
      setPreviewUrl(res.url);
      if (allIndex > -1) {
        fileList[allIndex] = {
          ...file,
          ossFile: params.ossFile,
          url: res.url,
        };
        setFileList(fileList, [fileList[allIndex]], ChangeType.PREVIEW);
        return;
      }
      return {
        ossFile: params.ossFile,
        url: res.url,
      };
    } catch {
      setLoadingStatus(2);
    }
  };
  // 重新检测
  const checkAll = async () => {
    const indexArr: number[] = [];
    await fileList.forEach(async (item, index) => {
      const newFile = await getOSSUrlAPI(item, -1);
      if (!newFile) return;
      indexArr.push(index);
      fileList[index] = {
        ...item,
        ...newFile,
      };
    });
    if (indexArr.length > 0) {
      await setFileList(
        fileList,
        indexArr.map((index) => fileList[index]),
        ChangeType.PREVIEW,
      );
    }
  };
  // 删除
  const onRemove = (file: YUploadFile) => {
    const { uid, disabled } = file;
    if (
      fileDisabled ||
      fileModalRef?.current?.filePreviewStatus > 0 ||
      disabled
    )
      return;

    YModal.confirm({
      title: '提示',
      content: '确定要删除此文件?',
      onOk: () => {
        const list = fileList.filter((item: any) => item.uid !== uid);
        setFileList(list, file ? [file] : [], ChangeType.REMOVE);
      },
      onCancel: () => {},
    });
  };
  // 预览
  const onPreview = async (file: YUploadFile, imgIndex: number) => {
    let { url, ossFile, fileType } = file;
    url = url || fileList[props.allIndex].url || previewUrl;
    if (
      !url ||
      fileModalRef?.current?.filePreviewStatus > 0 ||
      loadingStatus > 0
    )
      return;

    if (securityType === 'public') await checkAll();

    if (fileType === 'image') {
      imgModalRef.current.show(imgIndex);
    } else if (fileType !== 'image' && typeof window !== 'undefined') {
      fileModalRef.current.show(url, showFileModel ? ossFile : '');
    }
  };

  return {
    previewUrl,
    loadingStatus,
    getOSSUrlAPI,
    onPreview,
    onRemove,
  };
};
