import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { YUploadFile } from './common/interface';
import { ChangeType, YUploadProps } from './upload.interface';
import { getStringFile, getObjFile } from './common/utils';

export const useModel = (props: YUploadProps) => {
  const { quantity = 1, value: dataSource, onChange, setFileParams } = props;
  const fileListRef = useRef<YUploadFile[]>([]);
  const [fileList, setFileList] = useState<YUploadFile[]>([]);
  const changeFileList = (
    list: YUploadFile[],
    changeFileList?: YUploadFile[],
    type?: ChangeType,
  ) => {
    const dealList = (setFileParams && setFileParams(list)) || list;
    fileListRef.current = list;
    setFileList(dealList);
    if (changeFileList && type) {
      onChange && onChange(dealList, changeFileList, type);
    }
  };

  // 初始化
  const initFileList = () => {
    if (dataSource) {
      let list: YUploadFile[] = [];
      let file = {
        url: '',
        size: 100,
        name: '',
        type: '',
        uid: uuidv4(),
        originalName: '',
      };
      const originalList = !(
        typeof dataSource === 'object' && dataSource instanceof Array
      )
        ? [dataSource]
        : dataSource;
      // 默认值为数组
      originalList.length &&
        originalList.forEach((item) => {
          list.push({
            ...file,
            ...(typeof item === 'string'
              ? getStringFile(item)
              : getObjFile(item)),
            uid: uuidv4(),
          });
        });
      // 过滤地址为空的元素
      list = list.filter((item) => item.url);
      // 默认值过多
      if (list.length > quantity) {
        const allList = list.splice(0, quantity);
        changeFileList(
          allList,
          list.splice(quantity - 1, list.length),
          ChangeType.INIT,
        );
      } else {
        changeFileList(list);
      }
    }
  };

  useEffect(() => {
    if (
      !(
        Array.isArray(dataSource) &&
        JSON.stringify(dataSource) == JSON.stringify(fileList)
      )
    ) {
      initFileList();
    }
  }, [quantity, dataSource]);

  return {
    fileList,
    fileListRef,
    setFileList: changeFileList,
  };
};
