
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROGOODS";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 190 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}


  
    
      export interface CategoryTreeListV1Item {
        
          // 
          categoryNo: string;
        
          // 
          categoryName: string;
        
          // 
          level: number;
        
          // 
          sortNum: number;
        
          // 
          parentNo: string;
        
          // 
          icon: string;
        
          // 
          isLeaf: number;
        
          // 
          hasChild: number;
        
          // 
          categoryNoPath: string;
        
      }
    
  


export interface CategoryTreeListV1Input {
  
    
      /** 父类目编号(String) */
      parentNo?: string;
    
  
}

export interface CategoryTreeListV1Data {
  
    
      // T
      list: CategoryTreeListV1Item[];
    
      // 
      totalNumber: number;
    
      // 
      totalPage: number;
    
      // 
      rowId: string;
    
      // 
      hasNext: boolean;
    
      // 
      pageSize: number;
    
      // 
      pageIndex: number;
    
  
}

// 20450 【类目管理】- 类目树查询

  export const useGetCategoryTreeListV1 = () => {
    let isMount = true;
    const [isCategoryTreeListV1Loading, setIsCategoryTreeListV1Loading] = useState(false);
    const [dataCategoryTreeListV1, setDataCategoryTreeListV1] = useState<CategoryTreeListV1Data>({} as CategoryTreeListV1Data);
    const getCategoryTreeListV1Data = async(params: CategoryTreeListV1Input, config?: AxiosRequestConfig): Promise<CategoryTreeListV1Data> => {
      if(isMount){
        setIsCategoryTreeListV1Loading(true);
      }
      
      try{
        
          
          const res: any = await Request.get<CategoryTreeListV1Data>(`${basePath}/category/tree/list/v1?${querystring.stringify(params as unknown as ParsedUrlQueryInput)}`, config);
          
          
          
          if(isMount){
            setDataCategoryTreeListV1(res || {});
            setIsCategoryTreeListV1Loading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsCategoryTreeListV1Loading(false);
        }
        return false as unknown as CategoryTreeListV1Data;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isCategoryTreeListV1Loading,
      dataCategoryTreeListV1,
      getCategoryTreeListV1Data,
    }
  }

