import { useState, useEffect } from 'react';

import { AxiosRequestConfig } from 'axios';
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import { configs } from './config';

const projectId = 'COMMON';
let env;
if (typeof window === 'undefined') {
  env =
    (process.env.REACT_APP_ENV === 'prod'
      ? 'prod'
      : process.env.REACT_APP_ENV) || 'prod';
} else {
  env =
    window.localStorage.getItem('env') ||
    (process.env.REACT_APP_ENV === 'prod'
      ? 'prod'
      : process.env.REACT_APP_ENV) ||
    'prod';
}

const basePath =
  configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 199 : ''}`;

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
};

export interface OssGetgeneratepresignedurlInput {
  /**  */
  projectId: string;

  /**  */
  ossFile: string;

  /** 过期时间，单位：小时 */
  expire?: string;
}

export interface OssGetgeneratepresignedurlData {
  //
  url: string;
}

// 20887 使用签名URL临时授权

export const useGetOssGetgeneratepresignedurl = () => {
  let isMount = true;
  const [
    isOssGetgeneratepresignedurlLoading,
    setIsOssGetgeneratepresignedurlLoading,
  ] = useState(false);
  const [
    dataOssGetgeneratepresignedurl,
    setDataOssGetgeneratepresignedurl,
  ] = useState<OssGetgeneratepresignedurlData>(
    {} as OssGetgeneratepresignedurlData,
  );
  const getOssGetgeneratepresignedurlData = async (
    params: OssGetgeneratepresignedurlInput,
    config?: AxiosRequestConfig,
  ): Promise<OssGetgeneratepresignedurlData> => {
    if (isMount) {
      setIsOssGetgeneratepresignedurlLoading(true);
    }

    try {
      const res: any = await Request.get<OssGetgeneratepresignedurlData>(
        `${basePath}/oss/getGeneratePresignedUrl?${querystring.stringify(
          (params as unknown) as ParsedUrlQueryInput,
        )}`,
        config,
      );

      if (isMount) {
        setDataOssGetgeneratepresignedurl(res || {});
        setIsOssGetgeneratepresignedurlLoading(false);
      }
      return res;
    } catch (error) {
      console.log(error);
      if (isMount) {
        setIsOssGetgeneratepresignedurlLoading(false);
      }
      return (false as unknown) as OssGetgeneratepresignedurlData;
    }
  };

  useEffect(() => {
    return () => {
      isMount = false;
    };
  }, []);

  return {
    isOssGetgeneratepresignedurlLoading,
    dataOssGetgeneratepresignedurl,
    getOssGetgeneratepresignedurlData,
  };
};
