import React from 'react';
import { LayoutStyle } from './styles';
import {Header} from './header';
import {LayoutProps} from './layout.interface';

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <LayoutStyle>
      {/* {
        stage !== "auth" && <Header logo={config?.logo} menus={config?.menus || []} />
      } */}
      {children}
    </LayoutStyle>
  );
};