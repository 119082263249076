import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import {
  OSSImgConfig,
  ImgSuffixType,
  ProjectId,
  File,
  YUploadFile,
} from './common/interface';
// onChange事件类型
export enum ChangeType {
  REMOVE = 'remove',
  UPLOAD = 'upload',
  DRAG = 'dray',
  INIT = 'init',
  PREVIEW = 'preview',
}
// 默认值类型
export type ValueType = string | File;
export type UploadType = 'uploadFile' | 'uploadPicture' | 'thumbnail';
// oss相关配置
export interface OSSProps {
  // 是否启用Oss
  isOss?: boolean;
  // 项目ID
  projectId?: ProjectId;
  // 功能
  featureName?: string;
  // oss全地址
  ossDir?: string;
  // oss加密
  securityType?: 'public' | 'private';
  // 过期时间 - 单位为小时
  expire?: number;
}
// 裁剪相关
export interface cropperProps {
  // 是否裁剪
  ableCrop?: boolean;
  // 裁剪比例
  aspectRatio?: number;
  // 裁剪返回图片格式
  cropType?: ImgSuffixType;
  // 裁剪完成
  cropFinish?: (blob: any) => void;
}
// request上传配置
export interface RequestProps {
  // 使用默认上传接口时，自定义formData中的fileNames
  fileName?: string;
  // 使用request函数时file的key值
  name?: string | 'files';
}
// 文件限制相关配置
export interface CheckProps {
  // 上传类型
  accept?: ImgSuffixType | string;
  // 是否限制上传类型
  isAccept?: boolean;
  // 数量
  quantity?: number;
  // 文件大小 - 单位为兆
  maxSize?: number;
  // 图片宽度
  picWidth?: number;
  // 图片高度
  picHeight?: number;
}
// 预览相关配置
interface PreviewProps {
  // 自定义按钮内容
  buttonText?: string | React.ReactNode; //的点点滴滴
  // 是否禁用
  disabled?: boolean;
  // 取消预览
  hidePreview?: boolean;
  // 预览模式下显示的内容
  previewPlaceholder?: string | '-' | React.ReactNode;
  // 最多显示的图片数，不可与拖拽同时使用
  maxShow?: number;
  // 一行展示多少
  column?: number;
  // 是否竖状排列
  isVertical?: boolean;
  // 是否显示文案
  showFileName?: boolean;
  // 图片配置
  ossImgConfig?: OSSImgConfig;
  /*
   *  提示信息
   *  不传任何数据：显示默认值
   *  false：隐藏信息提示
   *  string：显示自定义值
   * */
  errMessage?: React.ReactNode | string | false;
  // 文件预览是是否展示文件弹框
  showFileModel?: boolean;
}
// 方法相关配置
export interface MethodProps {
  //
  setFileParams?: (fileList: YUploadFile[]) => YUploadFile[];
  // 上传服务
  request?: (formData: FormData | any) => Promise<ValueType | void>;
  // 图片变更
  onChange?: (
    dataSource: YUploadFile[],
    changeFileList: YUploadFile[],
    type: ChangeType,
  ) => void;
  // 上传完成
  uploadFinish?: (res: any) => void;
  // 自定义规则
  beforeUpload?: (file: RcFile, fileList: RcFile[]) => Promise<File>;
}

export interface YUploadProps
  extends OSSProps,
    RequestProps,
    CheckProps,
    PreviewProps,
    MethodProps,
    cropperProps {
  // 上传文件类型
  uploadType?: UploadType;
  // 默认值
  value?: ValueType[] | ValueType;
  // 多图上传
  multiple?: boolean;
  // 其他透传数据
  originalProps?: UploadProps;

  // 是否可拖拽
  isDrag?: boolean;
}
