import styled from 'styled-components';
import { Select } from 'antd';

export const AddBtnStyle = styled.div`
  background: #ddefff;
  text-align: center;
  display: block;
  width: 100%;
`;

export const SelectStyle = styled(Select as any)`
  min-width: ${(props) => (props.minwidth ? props.minwidth : '')};
`;

export const OptionStyle = styled(Select.Option)`
  .ant-select-item-option-content > span {
    display: block;
  }
`;

export const IconWrapStyle = styled.div`
  text-align: center;
  font-size: 20px;
`;
