
import Request from '@ywfe/request';
import {configs} from '../generated/config';
import { useLayoutContext } from './layout.context';
import {
  useGetUserLogout,
  usePostUserUpdatepasswd,
  usePostUserLogin,
  UserLoginInput,
  UserLoginData,
  usePostUserSendmtcode,
  UserSendmtcodeInput,
  usePostUserUpdatepasswdbymtcode,
  UserUpdatepasswdbymtcodeInput
} from '../generated/api';
import { PATH_MAP } from './constants';

interface ChangePasswordProps {
  oldPasswd: string;
  newPasswd: string;
}

export const useModel = () => {
  const { store } = useLayoutContext();
  const [_, dispatch] = store();
  const { getUserLogoutData } = useGetUserLogout();
  const { postUserUpdatepasswdData } = usePostUserUpdatepasswd();
  const { postUserLoginData } = usePostUserLogin();
  const { postUserSendmtcodeData } = usePostUserSendmtcode();
  const { postUserUpdatepasswdbymtcodeData } = usePostUserUpdatepasswdbymtcode();

  const login = async (values: UserLoginInput): Promise<UserLoginData> => {
    const res = await postUserLoginData({
      ...values,
      projectId: 'PROAUTH',
    });
    if (res) {
      dispatch({
        type: "login",
        payload: {
          data: {
            userName: res.userName,
            token: res.userToken,
          },
        },
      });
    }
    return res || {};
  };

  const loginout = async (): Promise<boolean> => {
    const res = await getUserLogoutData();
    if (res) {
      dispatch({
        type: "logout",
        payload: {
          data: {},
        },
      });
    }
    return res ? true : false;
  };

  const changePassword = async ({
    oldPasswd,
    newPasswd,
  }: ChangePasswordProps): Promise<boolean> => {
    const res = await postUserUpdatepasswdData({
      oldPasswd,
      newPasswd,
    });
    if (res) {
      dispatch({
        type: 'change password',
        payload: {
          data: {},
        },
      });
    }
    return res ? true : false;
  };

  const updatePasswordByMtCode = async ({telephone, passWd, mtCode}: UserUpdatepasswdbymtcodeInput) => {
    const res = await postUserUpdatepasswdbymtcodeData({
      telephone,
      passWd,
      mtCode
    });
    if(res){
      dispatch({
        type: 'change password',
        payload: {
          data: {},
        },
      });
    }
    return res ? true : false;
  }

  const switchAuthType = (authType: string) => {
    dispatch({
      type: "switch auth type",
      payload: {
        data: {
          authType
        },
      },
    });
  }

  const sendCode = async (data: UserSendmtcodeInput) => {
    const res = await postUserSendmtcodeData(data)
    return res;
  }

  const getDingCode = async () => {
    const projectId = "DING_TALK";
    let env;
    if(typeof window === 'undefined'){
      env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'debug';
    } else {
      env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'debug';
    }
    const basePath = configs[projectId][env]['APPLICATION_URL'];
    const appId = configs[projectId][env]['APP_ID'];
    const token= configs[projectId][env]['TOKEN'];
    const res = await Request.post(`${basePath}/robot/send?access_token=${token}`, {}, {
      headers: {
        "content-type": "application/json",
      }
    });
    console.log('getDingCode', res);
  }

  return {
    login,
    loginout,
    changePassword,
    updatePasswordByMtCode,
    switchAuthType,
    sendCode,
    getDingCode
  };
};
