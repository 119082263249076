import { useState, useEffect } from 'react';

import { AxiosRequestConfig } from 'axios';
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import { configs } from './config';

const projectId = 'COMMON';
let env;
if (typeof window === 'undefined') {
  env =
    (process.env.REACT_APP_ENV === 'prod'
      ? 'prod'
      : process.env.REACT_APP_ENV) || 'prod';
} else {
  env =
    window.localStorage.getItem('env') ||
    (process.env.REACT_APP_ENV === 'prod'
      ? 'prod'
      : process.env.REACT_APP_ENV) ||
    'prod';
}

const basePath =
  configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 199 : ''}`;

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
};

export interface OssGetsignatureinfoInput {
  /** 项目id */
  projectId: string;

  /** 上传路径 */
  dir: string;

  /** 回调url */
  callbackUrl?: string;

  /** 安全类型，public 或  private */
  securityType: string;
}

export interface OssGetsignatureinfoData {
  //
  accessId?: string;

  // 回调地址
  callback?: string;

  // 文件上传目录
  dir?: string;

  // 有效期，默认30秒
  expire?: string;

  // 上传地址
  host?: string;

  // 上传策略
  policy?: string;

  // 签名
  signature?: string;
}

// 18828 获取oss签名信息

export const useGetOssGetsignatureinfo = () => {
  let isMount = true;
  const [
    isOssGetsignatureinfoLoading,
    setIsOssGetsignatureinfoLoading,
  ] = useState(false);
  const [
    dataOssGetsignatureinfo,
    setDataOssGetsignatureinfo,
  ] = useState<OssGetsignatureinfoData>({} as OssGetsignatureinfoData);
  const getOssGetsignatureinfoData = async (
    params: OssGetsignatureinfoInput,
    config?: AxiosRequestConfig,
  ): Promise<OssGetsignatureinfoData> => {
    if (isMount) {
      setIsOssGetsignatureinfoLoading(true);
    }

    try {
      const res: any = await Request.get<OssGetsignatureinfoData>(
        `${basePath}/oss/getSignatureInfo?${querystring.stringify(
          (params as unknown) as ParsedUrlQueryInput,
        )}`,
        config,
      );

      if (isMount) {
        setDataOssGetsignatureinfo(res || {});
        setIsOssGetsignatureinfoLoading(false);
      }
      return res;
    } catch (error) {
      console.log(error);
      if (isMount) {
        setIsOssGetsignatureinfoLoading(false);
      }
      return (false as unknown) as OssGetsignatureinfoData;
    }
  };

  useEffect(() => {
    return () => {
      isMount = false;
    };
  }, []);

  return {
    isOssGetsignatureinfoLoading,
    dataOssGetsignatureinfo,
    getOssGetsignatureinfoData,
  };
};
