import styled from 'styled-components';
import { Layout as AntLayout, Menu as AndMenu } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';

// const __THEM_PATH_ = '../styles/antd-theme'

const { Content: AntContent, Sider: AntSider } = AntLayout;

export const MainLayoutStyle = styled(AntLayout as any)`
  position: relative;
  max-width: 100%;
  min-height: 100vh;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
`;

export const ContextLayoutStyle = styled(AntLayout as any)`
  position: relative;
  height: 100vh;
  overflow: auto;
  width: 100%;
  padding: 12px;
`;

export const Container: React.FunctionComponent<BasicProps> = styled(
  AntContent,
)`
  position: relative;
  box-sizing: border-box;
  background-color: none;
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const MenuStyle = styled(AndMenu)`
  height: 100%;
`;

export const ContextStyle = styled.div`
  background-color: #fff;
  padding: 12px;
  overflow: auto;
`;

export const SideBarStyle = styled(AntSider)`
  overflow: auto;
  height: 100vh;
  left: 0;
`;
