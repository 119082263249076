import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { SortableElement } from 'react-sortable-hoc';
import { YIcon } from '../../icon';
import { getOssImgConfig, substrName } from '../common/utils';
import { YUploadFile } from '../common/interface';
import { PreviewListProps, PreviewItemProps, SmallDashItemProps } from './interface';
import { ItemStyle } from './styles';
import { useModel } from './useModel';

const fileIcon: {[name: string]: string} = {
  xlsx: 'https://cdn.ywwl.com/bps/ywd/components/yupload/excel-big.png',
  xls: 'https://cdn.ywwl.com/bps/ywd/components/yupload/excel-big.png',
  pdf: 'https://cdn.ywwl.com/bps/ywd/components/yupload/pdf-big.png',
  ppt: 'https://cdn.ywwl.com/bps/ywd/components/yupload/ppt-big.png',
  pptx: 'https://cdn.ywwl.com/bps/ywd/components/yupload/ppt-big.png',
  doc: 'https://cdn.ywwl.com/bps/ywd/components/yupload/word-big.png',
  docx: 'https://cdn.ywwl.com/bps/ywd/components/yupload/word-big.png',
  zip: 'https://cdn.ywwl.com/bps/ywd/components/yupload/zip-big.png',
  unkown: 'https://cdn.ywwl.com/bps/ywd/components/yupload/unkown-big.png',
  empty: ''
}

// 省略号
const SmallDashItem: React.FC<SmallDashItemProps> = (props) => {
  const { uploadType, maxShow, column, showFileName } = props;
  return (
    <ItemStyle className={[uploadType, 'smalldash'].join(' ')} column={column} maxShow={maxShow} showFileName={showFileName}>
      <YIcon name="SmallDashOutlined" style={{ color: '#333' }} />
    </ItemStyle>
  )
}

const PreviewItem: React.FC<PreviewItemProps> = (props) => {
  const {
    fileModalRef,
    maxShow,
    allIndex,
    imgIndex,
    uploadType,
    file,
    style,
    ossImgConfig,
    fileDisabled,
    showFileName,
    column,
  } = props;
  const { fileType, originalName, name } = file;
  const fileName = originalName || name;
  const { getOSSUrlAPI, onPreview, onRemove, loadingStatus, previewUrl } = useModel(props);

  useEffect(() => {
    getOSSUrlAPI(file, allIndex);
  }, []);

  return maxShow && allIndex + 1 > maxShow ? <></> : (
    <ItemStyle
      className={uploadType}
      style={style}
      column={column}
    >
      <div className="img-wrap">
        <Spin spinning={loadingStatus === 1}>
          {/* 预览，展示图片 */}
          {fileType === 'image' ? (
            <div className="img-box">
              <img src={getOssImgConfig(previewUrl || file.url, ossImgConfig)} />
            </div>
          ) : (
            <div className="img-box" style={{padding: 5 }}>  
              <img src={file.icon || fileIcon[fileType || 'empty'] || fileIcon.unkown} />
            </div>
          )}
          {/* 遮照 */}
          <div
            className={[
              'fix',
              fileModalRef?.current?.filePreviewStatus > 0 ? 'wait' : '',
              loadingStatus !== 0 ? 'show' : ''
            ].join(' ')}
          >
            {loadingStatus !== 0 ? (
              <span onClick={() => getOSSUrlAPI(file, allIndex)}>
                <YIcon name="ReloadOutlined" />
              </span>
            ) : (
              <>
                <span onClick={() => onPreview(file, imgIndex)}>
                  <YIcon name="EyeOutlined" />
                </span>
                {!fileDisabled && !file.disabled && (
                  <span onClick={() => onRemove(file)}>
                    <YIcon name="DeleteOutlined" />
                  </span>
                )}
              </>
            )}
          </div>
        </Spin>
      </div>
      {/* 图片模式: 文件名显示 */}
      {showFileName && uploadType === 'uploadPicture' && (
        <p>{substrName(fileName)}</p>
      )}
      {/* 文字模式: 文件名显示 */}
      {uploadType !== 'uploadPicture' && (
        <>
          <p onClick={() => onPreview(file, imgIndex)}>
            {fileName.length > 8 ? (
              <>
                <span className="ellipsis">{fileName.substr(0, fileName.length - 7)}</span>
                <span>{fileName.substr(fileName.length - 7)}</span>
              </>
            ) : fileName}
          </p>
          <div
            className={[
              'close',
              file.disabled ? 'hide': '',
              fileDisabled ? 'delete': ''
            ].join(' ')}
            onClick={() => onRemove(file)}
          >
            <YIcon name="CloseOutlined" />
          </div>
        </>
      )}
      {uploadType === 'uploadFile' && (
        <Spin spinning={loadingStatus === 1} wrapperClassName="YUpload-text-loading">
          {loadingStatus === 2 && (
            <span onClick={() => getOSSUrlAPI(file, allIndex)}>
              <YIcon name="ReloadOutlined" />
            </span>
          )}
        </Spin>
      )}
    </ItemStyle>
  )
}
// 可拖拽
const DrayPreviewItem = SortableElement((props: PreviewItemProps) => (
  <PreviewItem
    {...props}
    style={{
      cursor: 'grab',
    }}
  />
))
// 
export const PreviewList: React.FC<PreviewListProps> = (props) => {
  const { fileList, isDrag, disabled, itemProps } = props;
  const { maxShow, ...other } = itemProps;
  const imgFileList = fileList.filter((item: YUploadFile) => item.fileType === 'image');
  return (
    <>
      {fileList.map((item, index) => {
        const commonItemProps = {
          ...other,
          fileList,
          index,
          key: `item-${item.uid}`,
          file: item,
          allIndex: index,
          imgIndex: imgFileList.findIndex((imgItem: YUploadFile) => imgItem.uid === item.uid),
        }
        return isDrag && !disabled ? (
          <DrayPreviewItem
            {...commonItemProps}
          />
        ) : (
          <PreviewItem
            {...commonItemProps}
            maxShow={maxShow}
          />
        )
      })}
      {!isDrag && maxShow && fileList.length > maxShow && (
        <SmallDashItem
          uploadType={itemProps.uploadType}
          maxShow={maxShow}
          column={itemProps.column}
          showFileName={itemProps.showFileName}
        />
      )}
    </>
  )
}