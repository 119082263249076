import styled from 'styled-components';
import { Layout as AntLayout, Button } from 'antd';
import { Input } from '@formily/antd-components';
import { BasicProps } from 'antd/lib/layout/layout';

const { Header: AntHeader } = AntLayout;

export const LayoutStyle = styled(AntLayout as any)`
  padding: 12px;
  position: relative;
  max-width: 100%;
  min-height: 100vh;
  padding: 0;
  width: 100%;
`;

export const HeaderStyle: React.FC<BasicProps> = styled(AntHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: relative;
  position: fixed;
  z-index: 1;
  width: 100%;
`;

export const HeaderRightStyle = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const LogoStyle = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 96px;
  }
`;

export const LoginStyle = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: #000;
  width: auto;
  height: auto;
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    -o-object-fit: cover;
    position: absolute;
    top: 0;
    z-index: -1;

  }
`;

export const FormStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 68.75%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  border-radius: 8px;
  box-shadow: 0 0 10px hsla(0, 0%, 58.8%, 0.2);
  overflow: hidden;
  z-index: 100;
`;
export const FormBoxStyle = styled.div`
  width: 400px;
  height: 100%;
  padding: 40px 30px;
  box-sizing: border-box;
`;

export const LoginLogoStyle = styled.img`
  display: block;
  margin: 0 auto 40px;
  height: 40px;
`;

export const CodeBtnStyle = styled(Button as any)`
  width: 100px;
  margin-left: 10px;
  
`;
