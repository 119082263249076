import { useState, useEffect } from 'react';

import { AxiosRequestConfig } from 'axios';
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import { configs } from './config';

const projectId = 'COMMON';
let env;
if (typeof window === 'undefined') {
  env =
    (process.env.REACT_APP_ENV === 'prod'
      ? 'prod'
      : process.env.REACT_APP_ENV) || 'prod';
} else {
  env =
    window.localStorage.getItem('env') ||
    (process.env.REACT_APP_ENV === 'prod'
      ? 'prod'
      : process.env.REACT_APP_ENV) ||
    'prod';
}

const basePath =
  configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 199 : ''}`;

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
};

export interface OssGetofficepreviewurlInput {
  /**  */
  projectId: string;

  /**  */
  ossFile: string;
}

export interface OssGetofficepreviewurlData {
  //
  accessToken?: string;

  //
  accessTokenExpiredTime?: string;

  // 预览地址
  previewURL?: string;

  //
  refreshToken?: string;

  //
  refreshTokenExpiredTime?: string;

  //
  requestId?: string;
}

// 20869 文档预览接口

export const useGetOssGetofficepreviewurl = () => {
  let isMount = true;
  const [
    isOssGetofficepreviewurlLoading,
    setIsOssGetofficepreviewurlLoading,
  ] = useState(false);
  const [dataOssGetofficepreviewurl, setDataOssGetofficepreviewurl] = useState<
    OssGetofficepreviewurlData
  >({} as OssGetofficepreviewurlData);
  const getOssGetofficepreviewurlData = async (
    params: OssGetofficepreviewurlInput,
    config?: AxiosRequestConfig,
  ): Promise<OssGetofficepreviewurlData> => {
    if (isMount) {
      setIsOssGetofficepreviewurlLoading(true);
    }

    try {
      const res: any = await Request.get<OssGetofficepreviewurlData>(
        `${basePath}/oss/getOfficePreviewURL?${querystring.stringify(
          (params as unknown) as ParsedUrlQueryInput,
        )}`,
        config,
      );

      if (isMount) {
        setDataOssGetofficepreviewurl(res || {});
        setIsOssGetofficepreviewurlLoading(false);
      }
      return res;
    } catch (error) {
      console.log(error);
      if (isMount) {
        setIsOssGetofficepreviewurlLoading(false);
      }
      return (false as unknown) as OssGetofficepreviewurlData;
    }
  };

  useEffect(() => {
    return () => {
      isMount = false;
    };
  }, []);

  return {
    isOssGetofficepreviewurlLoading,
    dataOssGetofficepreviewurl,
    getOssGetofficepreviewurlData,
  };
};
