export const destroyFns: Array<() => void> = [];
export let bodyWidth: number;
if (typeof window !== 'undefined') {
  bodyWidth = document.body.offsetWidth;
}
export const styleSize = (size: string | undefined) => {
  let width = size === 'fullScreen' ? '100%' : size === 'large' ? '80%' : '50%';
  return {
    width,
  };
};
export const calcBodyStyle = (
  size: string | undefined,
  title?: string,
  footer?: any,
) => {
  function calcHeight(title?: string, footer?: any) {
    const isfooterNull = footer === null;
    if (!title && isfooterNull) {
      return 'calc(100vh)';
    } else if (!isfooterNull && !title) {
      return 'calc(100vh - 53px)';
    } else if (isfooterNull && title) {
      return 'calc(100vh - 55px)';
    } else {
      return 'calc(100vh - 108px)';
    }
  }
  return {
    height: size === 'fullScreen' ? calcHeight(title, footer) : '',
    overflow: 'auto',
    maxHeight: calcHeight(title, footer),
  };
};
export const wrapWithClose = (method?: () => void) => {
  typeof method === 'function' && method();
};

/**
 * 复制粘贴方法
 * @param val // 需要复制的值
 */
export const copyValue = (val: string) => {
  let isSuccess = false;
  if (typeof window !== 'undefined') {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', val);
    input.select();
    if (document.execCommand('copy')) {
      isSuccess = true;
    }
    document.body.removeChild(input);
  }
  return isSuccess;
};
