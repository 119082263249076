import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { TreeProps } from 'antd/lib/tree';

import { YGoodsCategoriesProps } from './goodsCategories.interface';
import { GoodsCategoriesSinge } from './goodsCategories.singe';
import { GoodsCategoriesCascader } from './goodsCategories.cascader';
import { GoodsCategoriesTree } from './goodsCategories.tree';

export const YGoodsCategories: React.FC<YGoodsCategoriesProps<any>> = (
  props,
) => {
  const { config, onChange, originalProps } = props;
  const runModes = ['singe', 'cascader', 'tree'];

  let runMode: string = config.mode || 'singe';

  if (runModes.indexOf(runMode) === -1) {
    runMode = 'singe';
  }

  return (
    <>
      {/* singe */}
      {runMode === 'singe' && (
        <GoodsCategoriesSinge
          originalProps={originalProps as SelectProps<any>}
          config={config}
          onChange={onChange}
        />
      )}
      {/* cascader */}
      {runMode === 'cascader' && (
        <>
          <GoodsCategoriesCascader
            originalProps={originalProps}
            config={config}
            onChange={onChange}
          />
        </>
      )}
      {/* tree */}
      {runMode === 'tree' && (
        <>
          <GoodsCategoriesTree
            originalProps={originalProps as TreeProps}
            config={config}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
};
