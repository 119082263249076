import React from "react";
import { InputNumber } from "antd";
import CONFIG_CONST from "../filter.config";
import { RangeProps } from "../filter.interface";
import { MiddleLineStyle, DatePickerStyle } from "../styles";

export const RangeNumber: React.FC<RangeProps> = ({ placeholder, onChange, value = [], ...originalProps }) => {
  let placeholder1: string = CONFIG_CONST.PLACEHOLDER;
  let placeholder2: string = CONFIG_CONST.PLACEHOLDER;

  if (Array.isArray(placeholder)) {
    [placeholder1, placeholder2] = placeholder;
  }

  return (
    <DatePickerStyle>
      <InputNumber
        placeholder={placeholder1}
        value={Number(value[0]) || undefined}
        {...originalProps}
        onChange={(e: number | string | undefined) => { typeof onChange === "function" && onChange([e, value[1] || undefined]); }}
      />
      <MiddleLineStyle><span>-</span></MiddleLineStyle>
      <InputNumber
        placeholder={placeholder2}
        value={Number(value[1]) || undefined}
        {...originalProps}
        onChange={(e: number | string | undefined) => { typeof onChange === "function" && onChange([value[0] || undefined, e]); }}
      />
    </DatePickerStyle>
  );
};
