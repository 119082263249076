import React, { PureComponent } from 'react';
import OutContainer from './outContainer';
import { NewRootOutStyle, ViewHaveStyle } from './styles'
import { YIcon } from '../../icon';

import { YModalImgProps, YModalImgState, NextPropsType, EnventType } from '../modal.interface'

export class YImage extends PureComponent<YModalImgProps, YModalImgState> {
  constructor(props: YModalImgProps) {
    super(props)
    this.state = {
      keyboard: props.keyboard || true, // 是否支持键盘 esc 关闭
      visible: props.visible || false, // 默认是隐藏状态
      images: props.images || [],
      pdfPreivewUrl:
        'https://yungu-xiaozhao.oss-cn-hangzhou.aliyuncs.com/yungu-website/8707360717447786054ade69716aca7ca6cdb6c6197661402.jpg',
      index: props.index || 0, // 默认展示第一个图片
      rotate: props.rotate || 0, // 旋转角度默认是0
      translateX: 0,
      translateY: 6,
      scaleX: props.scale || 1,
      scaleY: props.scale || 1,
    };
    this.escFunction = this.escFunction.bind(this);
  }
  escFunction (event: any) {
    if (event.keyCode === 27) {
      this.setState({
        visible: false,
        index: 0,
      });
      this.initState();
      this.props.close && this.props.close();
    }
  }
  componentDidMount() {
    if (this.state.keyboard && typeof window !== 'undefined') {
      document.addEventListener('keydown', this.escFunction, false);
    }
  }
  componentWillUnmount() {
    if (this.state.keyboard && typeof window !== 'undefined') {
      document.removeEventListener('keydown', this.escFunction, false);
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps: NextPropsType) {
    this.setState({
      visible: nextProps.visible,
      index: nextProps.index || 0,
      images: nextProps.images
    })
  }

  moveImg = (event: EnventType) => {
    event.preventDefault()
    const { translateX, translateY } = this.state
    const disx = event.pageX - translateX
    const disy = event.pageY - translateY
    const _this = this;
    if (typeof window !== 'undefined') {
      document.onmousemove = (ev) => {
        _this.setState({
          translateX: ev.pageX - disx,
          translateY: ev.pageY - disy
        })
      }
      document.onmouseup = () => {
        document.onmousemove = null
        document.onmousedown = null
      }
    }
  }

  haveImagesHTML () {
    const { images } = this.state;
    const { index, translateX, translateY, scaleX, scaleY, rotate } = this.state;
    const imgStyle = {
      transform: `translateX(${translateX}px) translateY(${translateY}px) rotate(${rotate}deg) scaleX(${scaleX}) scaleY(${scaleY})`,
    };
    const itemWidth = images.length * 48 + (images.length - 1) * 3;
    const regPDF = /(.*)\.(pdf)$/i;
    const currentUrl = Array.isArray(images) && images.length > 0 ? images[index].src : '';
    return (
      <ViewHaveStyle>
        {
          regPDF.test(currentUrl) ? <div className='currentPdf'>
            <iframe style={imgStyle} className='pdf' src={currentUrl}></iframe></div>
            : <div className='currentImg'><img draggable onMouseDown={this.moveImg} style={imgStyle} src={currentUrl} /></div>
        }
        {Array.isArray(images) && images.length > 1 &&
          <div className="operator">
            <div className="iconOut" onClick={this.preAndDown.bind(this, 1)}><YIcon name="LeftOutlined" size={50} /></div>
            <div className="iconOut" onClick={this.preAndDown.bind(this, 2)}><YIcon name="RightOutlined" size={50} /></div>
          </div>
        }
        <div className='viewFooter'>
          <p className='viewTitle'>{images[index].alt}</p>
          <div className='viewControl'>
            <span onClick={this.zoom.bind(this, 0.1)} className='iconOut'><YIcon name="PlusOutlined" /></span>
            <span onClick={this.zoom.bind(this, -0.1)} className='iconOut'><YIcon name="MinusOutlined" /></span>
            <span onClick={this.rotate.bind(this, 1)} className='iconOut'><YIcon name="UndoOutlined" /></span>
            <span onClick={this.rotate.bind(this, 2)} className='iconOut'><YIcon name="RedoOutlined" /></span>
            <span onClick={this.handstand.bind(this, 1)} className='iconOut'><YIcon name="ColumnWidthOutlined" /></span>
            <span onClick={this.handstand.bind(this, 2)} className='iconOut'><YIcon name="ColumnHeightOutlined" /></span>
            <span
              className="iconOut"
              style={{ marginLeft: '30px' }}
              onClick={(e: any) => {
                e.stopPropagation;
                this.setState({
                  visible: false,
                  index: 0,
                });
                this.initState();
                this.props.close && this.props.close();
              }}
            >
              <YIcon name="CloseOutlined" />
            </span>
          </div>
          {Array.isArray(images) && images.length > 1 &&
            <div className='navbar'>
              <div className='imgList' style={{ width: itemWidth + "px" }}>
                {images.map((_el, i: number) => (
                  <span className='item' key={i}>
                    <div onClick={() => {
                      this.setState({
                        index: i,
                      });
                      this.initState();
                    }} className={i === index ? 'img' : undefined} />
                  </span>
                ))
                }
              </div>
            </div>
          }
        </div>
      </ViewHaveStyle>
    )
  }

  initState () {
    this.setState({
      translateX: 0,
      translateY: 6,
      rotate: this.props.rotate || 0,
      scaleX: this.props.scale || 1,
      scaleY: this.props.scale || 1,
    });
  }

  zoom (num: any) {
    let { scaleX, scaleY } = this.state;
    scaleX += num;
    scaleY += num;
    this.setState({
      scaleX,
      scaleY
    })
  }

  preAndDown (type: any) {
    let { images, index } = this.state;
    if (images.length == 0) {
      return;
    }
    if (type == 1) {
      index--;
      if (index < 0) {
        index = images.length - 1;
      }
    } else {
      index++;
      if (index > images.length - 1) {
        index = 0;
      }
    }
    this.setState({
      index
    })
    this.initState();
  }

  rotate (type: number) {
    let { rotate } = this.state;
    if (type === 1) {
      rotate -= 90;
    } else {
      rotate += 90;
    }
    this.setState({
      rotate
    })
  }

  handstand (type: number) {
    let { scaleX, scaleY } = this.state;
    if (type === 1) {
      scaleX = -Number(scaleX);
    } else {
      scaleY = -Number(scaleY);
    }
    this.setState({
      scaleX,
      scaleY
    })
  }

  render () {
    let { visible, images } = this.state;
    return (
      <OutContainer visible={visible}>
        <NewRootOutStyle visible={visible} >
          <div className='viewerMask'></div>
          <div className='closeBtn' onClick={() => {
            this.setState({
              visible: false,
              index: 0
            });
            this.initState();
            this.props.close && this.props.close();
          }}>
            <span className='close'><YIcon name="CloseOutlined" /></span>
          </div>
          {
            images.length > 0 ?
              <div className='imgContainer'>
                {this.haveImagesHTML()}
              </div>
              :
              <div>请输入要展示的图片</div>
          }
        </NewRootOutStyle>
      </OutContainer >
    )
  }
}
