import { useEffect } from 'react';
import { getRoutes } from '@ywfe/utils';
import { useGetAuthUserMenu } from '../generated/api';
import {
  YRouterItem,
  YRouterConfig,
  ApplicationConfig,
} from './application.interface';
import { useApplicationStore } from './useStore';

interface Permission {
  url: string;
  childMenus?: Permission[];
  resourceId: string;
  name: string;
  resourceType: string;
  [key: string]: any;
}

export const useSidebar = ({
  projectId,
  primaryDefaultMenu,
  secondaryDefaultMenu,
  routerConfig,
}: ApplicationConfig) => {
  const {
    getAuthUserMenuData,
    dataAuthUserMenu,
    isAuthUserMenuLoading,
  } = useGetAuthUserMenu();
  const [, dispatch] = useApplicationStore();

  useEffect(() => {
    getAuthUserMenuData({ projectId });
  }, []);

  const getMenu = (pathname: string) => {
    console.log('getMenu', pathname);
    if (!dataAuthUserMenu.list) {
      return [];
    }
    const res = getRoutes<Permission, YRouterConfig, YRouterItem>(
      dataAuthUserMenu.list as any,
      routerConfig,
      { primaryDefaultMenu, secondaryDefaultMenu },
      pathname,
    );

    dispatch({
      type: 'init_menu',
      payload: {
        data: res,
      },
    });
    console.log('menus', res);
    return res.menus || [];
  };

  return {
    getMenu,
    isAuthUserMenuLoading,
  };
};
