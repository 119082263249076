import { useState, useEffect, useRef } from 'react';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import Request from '@ywfe/request';
import { RcFile } from 'antd/lib/upload/interface';
import {
  usePostNewResourceUploadfiles,
  useGetOssGetsignatureinfo,
  OssGetsignatureinfoData,
} from '../../generated/api';
import {
  getFileType,
  getXmlNode,
  transformFileName,
  checkMultiple,
  checkSize,
  checkAccept,
  checkWidthHeight,
} from '../common/utils';
import { YUploadFile } from '../common/interface';
import { ChangeType } from '../upload.interface';
import {
  UploadButtonProps,
  OSSDataParams,
  originalFileProps,
} from './interface';

export const useModel = (props: UploadButtonProps) => {
  let {
    uploadType,
    securityType,
    fileList,
    isOss,
    projectId,
    featureName,
    ossDir,
    name,
    request: requestMethod,
    fileName: fileNameProp,
    uploadFinish,
    setFileList,
    beforeUpload: customBeforeUpload,
    fileListRef,
    multiple,
    maxSize,
    accept,
    isAccept = true,
    ableCrop,
    picHeight,
    picWidth,
    cropperModalRef,
  } = props;

  const isRequestOss = isOss && projectId && (featureName || ossDir);
  accept =
    uploadType !== 'uploadPicture' && !accept ? '.pdf, .xlsx, .xls' : accept;
  accept =
    uploadType === 'uploadPicture' && !accept
      ? 'image/jpg, image/JPG, image/jpeg, image/JPEG, image/png, image/PNG, image/webp, image/WEBP'
      : accept;
  accept = !isAccept ? '' : accept;

  const { postNewResourceUploadfilesData } = usePostNewResourceUploadfiles();
  const {
    getOssGetsignatureinfoData,
    isOssGetsignatureinfoLoading,
  } = useGetOssGetsignatureinfo();

  const uploadingPreRef = useRef<{ status: number; list: RcFile[] }>({
    status: 0,
    list: [],
  });
  const [fileName, setFileName] = useState(''); // 上传文件名
  const [uploadingStatus, setUploadingStatus] = useState<number>(0); // 上传状态
  const [OSSData, setOSSData] = useState<OSSDataParams>({
    host: '',
    accessId: '',
    policy: '',
    signature: '',
    dir: '',
  });

  // oss
  const getOSSConfig = () => {
    return new Promise(async (resolve) => {
      if (isRequestOss) {
        const res = await getOSSDataAPI();
        setOSSData(res as OSSDataParams);
        resolve(null);
      } else {
        resolve(null);
      }
    });
  };
  const getOSSDataAPI = async (): Promise<OssGetsignatureinfoData> => {
    const date = new Date();
    const dateFile = `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()}/`;
    const typeFile = uploadType === 'uploadPicture' ? 'image-cdn' : 'file-cdn';
    // 确保ossDir地址, 开头无/, 结尾有/
    if (ossDir) {
      ossDir = ossDir.charAt(ossDir.length - 1) === '/' ? ossDir : ossDir + '/';
      ossDir =
        ossDir.charAt(0) === '/' ? ossDir.substr(1, ossDir.length - 1) : ossDir;
    }
    const res: any = await getOssGetsignatureinfoData({
      projectId: projectId || '',
      dir: ossDir || `${projectId}/${featureName}/${typeFile}/${dateFile}`,
      securityType,
    });
    return res;
  };
  // 裁剪
  const cutImgPrivate = async (file: RcFile, uploadFileList: RcFile[]) => {
    return new Promise(async (resolve, reject) => {
      if (ableCrop) {
        const fileIndex = uploadFileList.findIndex(
          (item) => item.uid === file.uid,
        );
        if (fileIndex > 0) {
          reject();
          return;
        }
        const srcList: string[] = [];
        const fileList: any[] = [];
        // 校验
        for (let index = 0; index < uploadFileList.length; index++) {
          try {
            await checkFile(uploadFileList[index], uploadFileList);
            fileList.push(uploadFileList[index]);
          } catch (_error) {}
        }

        fileList.forEach((item) => {
          const reader = new FileReader();
          reader.readAsDataURL(item);
          reader.onload = (event: ProgressEvent<FileReader>) => {
            srcList.push(
              (event && event.target && (event.target.result as string)) || '',
            );
            if (srcList.length === fileList.length) {
              cropperModalRef.current.setCropSrc(srcList);
              cropperModalRef.current.setUploadFileList(fileList);
              cropperModalRef.current.setCropSrcModalShow(1);
            }
          };
        });
        reject();
      } else {
        resolve({ result: true });
      }
    }).then(
      () => {
        return file;
      },
      () => {
        return Promise.reject('打开裁剪弹框，终止上传');
      },
    );
  };
  // 上传到oss
  const uploadToOssAPI = async (
    host: string,
    file: RcFile,
    formData: FormData,
  ) => {
    formData.append('key', transformFileName(file, OSSData.dir));
    formData.append('policy', OSSData.policy);
    formData.append('OSSAccessKeyId', OSSData.accessId);
    formData.append('success_action_status', '201');
    formData.append('signature', OSSData.signature);
    formData.append('file', file, fileName);

    try {
      const res: any = await Request.post(host, formData);
      const ossFile = res && getXmlNode(res, 'Key');
      const url = res && getXmlNode(res, 'Location');
      return {
        url: securityType === 'private' ? '' : url,
        fileType: getFileType(url),
        ossFile,
      };
    } catch (error) {
      // reduceUploadStatusPrivate();
    }
  };

  // 上传到接口
  const baseRequestAPI = async (formData: any): Promise<any> => {
    if (fileNameProp) formData.append('fileNames', fileNameProp);
    const res: any = await postNewResourceUploadfilesData(formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return (
      res &&
      res.list &&
      res.list[0] && {
        url: res.list[0].uploadAddress,
        fileName: res.list[0].uploadFileName,
      }
    );
  };
  // 上传状态判断
  const reduceUploadStatusPrivate = (isAdd?: boolean) => {
    const { status, list } = uploadingPreRef.current;
    const nextStatus = isAdd ? status + 1 : status - 1;
    uploadingPreRef.current = {
      status: nextStatus,
      list: nextStatus ? list : [],
    };
    setUploadingStatus(nextStatus);
  };
  // 检测
  const checkFile = async (file: RcFile, uploadFileList: RcFile[]) => {
    const fileNumLimit =
      multiple && (await checkMultiple(file, uploadFileList, props));
    const sizeLimit = maxSize && (await checkSize(file, props));
    const acceptLimit = accept && (await checkAccept(file, accept));
    const widthHeightLimit =
      getFileType(file.name) === 'image' &&
      !ableCrop &&
      picWidth &&
      picHeight &&
      (await checkWidthHeight(file, props));
    const customLimit =
      customBeforeUpload && (await customBeforeUpload(file, uploadFileList));

    return (
      fileNumLimit &&
      sizeLimit &&
      acceptLimit &&
      widthHeightLimit &&
      customLimit
    );
  };
  // 上传前校验
  const beforeUpload: any = async (file: RcFile, uploadFileList: RcFile[]) => {
    //TODO
    const { status, list } = uploadingPreRef.current;
    const index = list.findIndex((item: RcFile) => item.uid === file.uid);
    if (list.length && status && (status >= list.length || index === -1)) {
      return false;
    }
    setFileName(file.name);

    const ossCheck =
      isRequestOss &&
      !(OSSData.expire && Number(OSSData.expire) * 1000 >= Date.now()) &&
      (await getOSSConfig());
    const fileLimit =
      !(uploadType === 'uploadPicture' && ableCrop) &&
      (await checkFile(file, uploadFileList));

    uploadType === 'uploadPicture' &&
      ableCrop &&
      (await cutImgPrivate(file, uploadFileList));

    uploadingPreRef.current = { status: 0, list: [...uploadFileList] };
    return ossCheck && fileLimit;
  };
  // 上传中
  const customRequest = async (file: RcFile) => {
    // 校验
    const { status, list } = uploadingPreRef.current;
    const index = list.findIndex((item: RcFile) => item.uid === file.uid);
    if (list.length && status && (status >= list.length || index === -1)) {
      return false;
    }
    const formData = new FormData();
    const fileName = file.name || `${uuidv4()}.png`;

    await reduceUploadStatusPrivate(true);

    // 是否走oss
    if (isRequestOss) {
      try {
        uploadToOssAPI(OSSData.host, file, formData).then((res: any) => {
          reduceUploadStatusPrivate();
          //TODO
          if (!res) return;
          message.success('上传成功！');
          const { url, ...other } = res;
          const lastName = url.split('/').pop() || '';
          setFileName(lastName);
          onUploadDonePrivate(url, lastName, {
            ...other,
            originalName: fileName,
          });
          if (uploadFinish) uploadFinish(res);
        });
      } catch (error) {
        reduceUploadStatusPrivate();
      }
    } else {
      formData.append(name, file, fileName);

      const apiRequestMethod = requestMethod || baseRequestAPI;
      try {
        const res: any = await apiRequestMethod(formData);
        reduceUploadStatusPrivate();
        //TODO
        if (!res) return;
        message.success('上传成功！');

        if (typeof res === 'string') {
          onUploadDonePrivate(res, fileName, {
            originalName: fileName,
            fileType: getFileType(res),
          });
        } else {
          let imgObj = res;
          if (res.list && res.list[0]) imgObj = res.list[0];

          const {
            name,
            fileName,
            uploadFileName,
            url,
            fileUrl,
            uploadAddress,
          } = imgObj;

          const lastFileName = fileName || name || uploadFileName;
          const imgUrl = fileUrl || url || uploadAddress;
          if (lastFileName) setFileName(lastFileName);
          if (imgUrl)
            onUploadDonePrivate(imgUrl, lastFileName, {
              originalName: fileName,
              fileType: getFileType(imgUrl),
            });
        }
        if (uploadFinish) uploadFinish(res);
      } catch (e) {
        reduceUploadStatusPrivate();
      }
    }
  };
  // 上传完成
  const onUploadDonePrivate = (
    imgUrl: string,
    apiFileName: string = '',
    originalprops: originalFileProps,
  ) => {
    if (!imgUrl && !originalprops.ossFile) return;

    let tempList = [...fileListRef.current];
    const fileItem: YUploadFile = {
      ...originalprops,
      uid: uuidv4(),
      size: 100,
      name: apiFileName || fileName || imgUrl.split('/').pop() || '',
      status: 'done',
      url: imgUrl,
      type: '',
    };
    tempList.push({ ...fileItem });
    fileListRef.current = tempList;
    setFileList(
      fileListRef.current,
      [fileItem],
      imgUrl ? ChangeType.UPLOAD : undefined,
    );
  };

  return {
    beforeUpload,
    customRequest,
    data: {
      accept,
      uploadingStatus,
      isOssGetsignatureinfoLoading,
    },
  };
};
