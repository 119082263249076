import React, { useEffect } from 'react';
import {useModel} from './useModel';

export const LoginScan : React.FC = () => {
  const {getDingCode} = useModel();
  useEffect(() => {
    getDingCode()
  }, [])
  return (
      <div>404</div>
  )
}