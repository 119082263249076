import styled from 'styled-components';
import { ItemStyleProps } from './interface';

export const ItemStyle = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  flex-shrink: 0;
  background: #fff;
  user-select: none;

  .img-wrap {
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 104px;
    width: 104px;
    position: relative;
    .img-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: #fff;
      img {
        max-width: 100%;
        max-height: 100%;
      }

      &.file-box {
        padding: 5px;
      }
    }

    .fix {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent;
      transition: all 0.3s;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      & > span {
        width: 24px;
        text-align: center;
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &.wait > span {
        cursor: wait;
      }

      &.show {
        display: flex !important;
        background-color: rgba(0, 0, 0, 0.5);
        & > span {
          opacity: 1;
        }
      }
    }
  }

  p {
    padding: 2px 0 0;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    word-break: break-all;
  }

  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-spin-blur {
    width: 100%;
    height: 100%;
  }

  .YUpload-text-loading {
    width: 16px;
    height: 12px;
    margin-right: 5px;
    flex-shrink: 0;
    .ant-spin-dot.ant-spin-dot-spin {
      height: 16px;
    }
    &.ant-spin-container,
    span {
      width: 16px;
      height: 12px;
      display: block;
      font-size: 12px !important;
    }
    & > div > .ant-spin .ant-spin-dot {
      margin: -8px;
    }
  }

  /* 拖拽 */
  &.SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0 1px 6px, rgba(0, 0, 0, 0.075) 0 1px 4px;
    background-color: #ddd;
  }
  // 图片模式
  &.uploadPicture {
    width: 104px;
    margin: 0 8px 8px 0;

    ${(props: ItemStyleProps) =>
      props.column
        ? `
      &:nth-child(${props.column}n) {
        margin-right: 0;
      }
    `
        : ''};

    .img-wrap:hover {
      .fix {
        background-color: rgba(0, 0, 0, 0.5);
        & > span {
          opacity: 1;
        }
      }
    }
  }
  /* 缩略图 + 文字列表 通用样式 */
  &.thumbnail,
  &.uploadFile {
    ${(props: ItemStyleProps) => {
      const column = props.column || 1;
      return `
        width: calc((100% - 10px * ${column - 1})/ ${column});
        &:nth-child(${column}n) {
          margin-right: 0;
        }
      `;
    }}
    margin: 10px 10px 0 0;
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;
      flex-grow: 1;
      .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      span {
        white-space: nowrap;
      }
    }

    .close {
      span {
        font-size: 12px !important;
        width: 12px;
        height: 12px;
        line-height: 12px;
        cursor: pointer;
      }

      &.hide {
        opacity: 0 !important;
      }

      &.delete {
        display: none;
      }
    }

    &:hover {
      p {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
  /* 缩略图 */
  &.thumbnail {
    height: 40px;
    background: #f2f2f2;
    padding: 5px 12px;

    .img-wrap {
      border: none;
      background: transparent;
      width: 30px;
      height: 30px;
      padding: 0;
      flex-shrink: 0;

      .img-box {
        background: transparent;
        padding: 0 !important;
      }

      .fix {
        display: none;
      }
    }

    p {
      padding: 0 0 0 8px;
    }
  }
  /* 文字列表 */
  &.uploadFile {
    margin: 0 24px 0 0;
    height: 28px;
    padding: 5px;
    ${(props: ItemStyleProps) => {
      const column = props.column || 1;
      return `
        width: calc((100% - 24px * ${column - 1})/ ${column});
      `;
    }};
    .img-wrap {
      display: none;
    }

    p {
      line-height: 28px;
      padding: 0;
    }

    .close {
      opacity: 0;
      margin: 0 5px;
    }

    &:hover {
      .close {
        opacity: 1;
      }
    }
  }

  &.smalldash {
    & > span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      background: #f2f2f2;
      text-align: center;
      height: 14px;
      transform: translate(-50%, -50%);

      .anticon {
        font-size: 26px !important;
        width: 16px;
        overflow: hidden;
        transform: translateY(-25%);
      }
    }

    &.uploadPicture {
      min-height: 28px;
      ${(props: ItemStyleProps) => {
        return props.maxShow && (!props.column || props.column > props.maxShow)
          ? `
          width: 28px;
        `
          : '';
      }}
      ${(props: ItemStyleProps) => {
        return props.showFileName
          ? `
          height: 104px;
        `
          : '';
      }}
    }

    &.uploadFile > span {
      left: 17px;
    }

    &.thumbnail {
      ${(props: ItemStyleProps) => {
        const column = props.column || 1;
        return column === 1
          ? `
          background: transparent;
          & > span {
            left: 24px;
          }
        `
          : '';
      }}
    }
  }
`;
