import React from 'react';
import { Popover, Tooltip } from 'antd';

import { YPopoverProps } from './popover.interface';
import { TitleStyle, GlobalStyle } from './styles';
import { InfoCircleOutlined } from '@ant-design/icons/lib';

export const YPopover: React.FC<YPopoverProps> = ({ children, style, title, content, type, originProps }) => {

  const box = () => {
    return (
      <GlobalStyle style={style}>
        {title && <TitleStyle>{title}</TitleStyle>}
        <div>{typeof content === 'function' ? content() : content}</div>
      </GlobalStyle>
    );
  };

  const Child = children ? children : <InfoCircleOutlined />;

  return (
    <>
      {type === 'tips'
        ? <Tooltip title={content} {...originProps}>
          {Child}
        </Tooltip>
        : <Popover content={box} {...originProps}>
          {Child}
        </Popover>}
    </>
  );
};
