import React, { useState, useEffect } from 'react';
import { YImageProps, ImageParams } from './interface';
import { ImageStyle } from './styles';
import { YModal } from '../../modal';
import { getOssImgConfig } from '../common/utils';

export const YImage = (props: YImageProps) => {
  const [visible, setVisible] = useState(false)
  const [imgIndex, setImgIndex] = useState(0)
  const [imgSite, setImgSite] = useState<ImageParams[]>([])

  const { value, alt, classname = '', styles = {}, ossImgConfig, modalProps = {} } = props

  useEffect(() => {
    initDataSource()
  }, [props])

  const initDataSource = () => {
    if (value) {
      if (typeof value === 'string') {
        setImgSite([{ src: value, alt: alt }])
      } else if (typeof value === 'object' && (value instanceof Array))  {
        const imgObj =  value.map((item: ImageParams | string) => {
          if (typeof item === 'string') {
            return { src: item }
          } else {
            return item
          }
        })
        setImgSite(imgObj)
      }
    }
  }

  return (
    <>
      <YModal.Image
        {...modalProps}
        visible={visible}
        images={imgSite}
        index={imgIndex}
        close={() => setVisible(false)}
      />
      <>
        {imgSite.map((item, index) => (
          <ImageStyle
            src={getOssImgConfig(item.src, ossImgConfig)}
            alt={item.alt || ''}
            className={classname}
            style={styles}
            key={`${index}-${item.src}`}
            length={imgSite.length}
            onClick={() => {
              setVisible(true)
              setImgIndex(index)
            }}
          />
        ))}
      </>
      
    </>
  )
}
