import React from "react";
import { DatePicker } from "antd";
import moment from 'moment';
import CONFIG_CONST from "../filter.config";
import { RangeProps } from "../filter.interface";
import { MiddleLineStyle, DatePickerStyle } from "../styles";
import { isNullOrEmpty } from "../utils";

export const DatePickerLine: React.FC<RangeProps> = ({ placeholder, onChange, value = [], format = 'YYYY-MM-DD', ...originalProps }) => {
  let placeholder1: string = CONFIG_CONST.PLACEHOLDER;
  let placeholder2: string = CONFIG_CONST.PLACEHOLDER;

  if (Array.isArray(placeholder)) {
    [placeholder1, placeholder2] = placeholder;
  }

  function isEffective(value: string | undefined): any {
    return isNullOrEmpty(value) ? undefined : value;
  }

  return (
    <DatePickerStyle>
      <DatePicker
        placeholder={placeholder1}
        format={format}
        {...originalProps}
        value={isEffective(value[0]) && moment(isEffective(value[0]))}
        onChange={(date) => {
          if (typeof onChange === 'function') {
            if (isEffective(value[1])) {
              onChange([date, moment(isEffective(value[1]))])
            } else {
              onChange([date, undefined])
            }
          }
        }}
      />
      <MiddleLineStyle><span>-</span></MiddleLineStyle>
      <DatePicker
        placeholder={placeholder2}
        format={format}
        role='isDate'
        {...originalProps}
        value={isEffective(value[1]) && moment(isEffective(value[1]))}
        onChange={(date) => {
          if (typeof onChange === 'function') {
            if (isEffective(value[0])) {
              onChange([moment(isEffective(value[0])), date])
            } else {
              onChange([undefined, date])
            }
          }
        }}
      />
    </DatePickerStyle>
  );
};
