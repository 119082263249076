
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROSUPPLIER";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 184 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}


  
    
      export interface NewBusinessPagelistItem {
        
          // 公司名称
          name?: string;
        
          // id
          id?: number;
        
          // 主营类目
          type?: string;
        
          // 1:待联系,2:洽谈中,3:未接入,4:已接入
          speed?: string;
        
          // 状态名称
          speedName?: string;
        
          // 负责人
          adminName?: string;
        
          // 主营品牌
          mainBrand?: string;
        
          // 采销负责人
          managerName?: string;
        
          // 负责人
          itemManager?: string;
        
          // 采销负责人code
          managerCode?: string;
        
          // 创建时间
          createTime?: any;
        
          // 操作时间
          auditTime?: any;
        
          // 变更记录
          context?: string;
        
      }
    
  


export interface NewBusinessPagelistInput {
  
    
      /**  */
      name?: string;
    
  
    
      /**  */
      type?: string;
    
  
    
      /**  */
      mainBrand?: string;
    
  
    
      /**  */
      itemManager?: string;
    
  
    
      /**  */
      speed?: string;
    
  
    
      /**  */
      createTimeStart?: string;
    
  
    
      /**  */
      createTimeEnd?: string;
    
  
    
      /**  */
      sord?: string;
    
  
    
      /**  */
      pageSize?: string;
    
  
    
      /**  */
      pageIndex?: string;
    
  
    
      /**  */
      rowId?: string;
    
  
    
      /**  */
      jumpType?: string;
    
  
    
      /**  */
      searchCount?: string;
    
  
    
      /**  */
      orderObjects?: string;
    
  
}

export interface NewBusinessPagelistData {
  
    
      // T
      list?: NewBusinessPagelistItem[];
    
      // 
      totalNumber?: number;
    
      // 
      totalPage?: number;
    
      // 
      rowId?: string;
    
      // 
      hasNext?: boolean;
    
      // 
      pageSize?: number;
    
      // 
      pageIndex?: number;
    
  
}

// 18869 查询列表

  export const useGetNewBusinessPagelist = () => {
    let isMount = true;
    const [isNewBusinessPagelistLoading, setIsNewBusinessPagelistLoading] = useState(false);
    const [dataNewBusinessPagelist, setDataNewBusinessPagelist] = useState<NewBusinessPagelistData>({} as NewBusinessPagelistData);
    const getNewBusinessPagelistData = async(params: NewBusinessPagelistInput, config?: AxiosRequestConfig): Promise<NewBusinessPagelistData> => {
      if(isMount){
        setIsNewBusinessPagelistLoading(true);
      }
      
      try{
        
          
          const res: any = await Request.get<NewBusinessPagelistData>(`${basePath}/new/business/pageList?${querystring.stringify(params as unknown as ParsedUrlQueryInput)}`, config);
          
          
          
          if(isMount){
            setDataNewBusinessPagelist(res || {});
            setIsNewBusinessPagelistLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsNewBusinessPagelistLoading(false);
        }
        return false as unknown as NewBusinessPagelistData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isNewBusinessPagelistLoading,
      dataNewBusinessPagelist,
      getNewBusinessPagelistData,
    }
  }

