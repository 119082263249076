import styled from 'styled-components';

type ImgShowType = {
  width?: number;
  height?: number;
  src?: string;
};
export const ImgShow = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${(props: ImgShowType) => props.width}px;
  height: ${(props: ImgShowType) => props.height}px;
  background: url(${(props: ImgShowType) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
interface visibleType {
  visible?: boolean;
}
export const NewRootOutStyle = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1005;
  transition: all 0.5s;
  opacity: ${(props: visibleType) => (props.visible ? '1' : '0')};
  visibility: ${(props: visibleType) => (props.visible ? 'visible' : 'hidden')};
  &[opacity='0'] {
    display: none;
  }
  & .viewerMask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(55, 55, 55, 0.6);
    height: 100%;
    filter: alpha(opacity=50);
    z-index: 1000;
  }
  .closeBtn {
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 0 0 0 40px;
    cursor: pointer;
    z-index: 1010;
    background-color: rgba(0, 0, 0, 0.5);
    .close {
      font-size: 16px;
      color: #ffffff;
      position: relative;
      top: 4px;
      left: 18px;
    }
  }
`;
export const ViewHaveStyle = styled.div`
  position: fixed;
  right: 0;
  top: 2px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
  z-index: 1005;
  .currentImg {
    min-width: 50%;
    max-width: 60%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    img {
      max-width: 100%;
      max-height: 100%;
      transition-property: width, height, margin, transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      cursor: move;
    }
  }
  .currentPdf {
    width: 100vw;
    .pdf {
      min-width: 80vw;
      max-width: 90vw;
      height: 80vh;
      margin: 0 auto;
      border: none;
      position: relative;
      top: 6px;
      transition-property: width, height, margin, transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }
  }
  .operator {
    position: fixed;
    display: flex;
    justify-content: space-between;
    right: 0;
    bottom: 50%;
    left: 0;
    overflow: hidden;
    text-align: center;
    z-index: 1005;
    padding: 0 20px;
    .iconOut {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 66px;
      height: 66px;
      border-radius: 50%;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.8s;
      &:hover {
        background-color: #fff;
        span {
          color: rgba(0, 0, 0, 0.5);
        }
      }
      span {
        font-size: 50px;
        color: #ffffff;
      }
    }
  }
  .viewFooter {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    text-align: center;
    z-index: 1005;
    .viewTitle {
      margin: 0;
      margin-bottom: 6px;
      opacity: 0.8;
      color: #ccc;
      font-size: 15px;
      padding: 6px 0;
    }
    .viewControl {
      margin-bottom: 12px;
    }
    .iconOut {
      display: inline-block;
      width: 28px;
      height: 28px;
      border-radius: 28px;
      margin-right: 6px;
      cursor: pointer;
      line-height: 28px;
      background-color: rgba(0, 0, 0, 0.5);
      span {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .navbar {
      // margin-top: 12px;
      margin-bottom: 5px;
      overflow: hidden;
      .imgList {
        height: 24px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 99px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        .item {
          display: inline-block;
          cursor: pointer;
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
          div {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #fff;
            transition: opacity 0.5s;
            opacity: 0.5;
          }
          .img {
            opacity: 1;
          }
        }
      }
    }
  }
`;
