import React, { useState, useEffect } from 'react';
import { YIconProps } from './icon.interface';
import * as Icons from '@ant-design/icons';
import { any } from 'ramda';

interface Icon {
  [key: string]: any
}

const icons = Icons as Icon;

export const YIcon: React.FC<YIconProps> = (props) => {
  // type: primary/error/warning/success/default/black/white

  const { name, type, size, style } = props;
  const renderIcon = (name: string) => {
    let color = '#333'
    let fontSize = '16px'
    if (size) {
      if (typeof(size) === 'number') {
        fontSize = `${size || 16}px`
      } else {
        fontSize = size
      }
    }
    return React.createElement(icons[name], {
      className: `${type}-color`,
      style: {
        fontSize,
      },
    })
  }
  return (
    <span style={style || {}}>
      {renderIcon(name)}
    </span>
  )

}
