import React from 'react';
import { Modal } from 'antd';
import { YModalProps } from './modal.interface';
import { styleSize, wrapWithClose, calcBodyStyle } from './util';

export const OriginModal: any = (props: YModalProps) => {
  let {
    size,
    title,
    okText,
    cancelText,
    visible,
    onOk,
    onCancel,
    content,
    children,
    footer,
    bodyStyle,
  } = props;
  let container;
  if (typeof window !== 'undefined') {
    container = document.body;
  }
  return (
    <Modal
      title={title}
      okText={okText || '确定'}
      cancelText={cancelText || '取消'}
      keyboard={false}
      centered={true}
      maskClosable={false}
      width={styleSize(size).width}
      getContainer={container}
      destroyOnClose
      {...props}
      bodyStyle={{ ...calcBodyStyle(size, title, footer), ...bodyStyle }}
      visible={visible}
      onOk={() => {
        wrapWithClose(onOk);
      }}
      onCancel={() => {
        wrapWithClose(onCancel);
        visible = false;
      }}
    >
      {children
        ? children
        : typeof content === 'function'
        ? content()
        : content}
    </Modal>
  );
};
