
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROSUPPLIER";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 184 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}


  
    
      export interface NewAdminSupplierPageItem {
        
          // 
          supplierNo?: string;
        
          // 
          supplierName?: string;
        
      }
    
  


export interface NewAdminSupplierPageInput {
  
    
      /** 供应商编号 */
      supplierNo?: string;
    
  
    
      /**  */
      supplierName?: string;
    
  
    
      /**  */
      contactPerson?: string;
    
  
    
      /**  */
      updateTimeBegin?: string;
    
  
    
      /**  */
      updateTimeEnd?: string;
    
  
    
      /** 企业认证状态，0：未认证；1：管理员资料已提交；2：企业基本资料(没有申请表)已提交；3：已提交待审核；4：审核通过；5：审核不通过；6人工初审通过 */
      status?: string;
    
  
    
      /** 和partnerTypeList二选一 */
      partnerTypes?: string;
    
  
    
      /**  */
      partnerTypeList?: string;
    
  
    
      /** 采销负责人code */
      managerCode?: string;
    
  
    
      /** 是否是审核页面,true:查询所有；false：根据采销权限查询 */
      isCheck?: string;
    
  
    
      /** 业务分组 */
      businessGroup?: string;
    
  
    
      /** 禁用状态 */
      isUse?: string;
    
  
    
      /**  */
      pageSize?: string;
    
  
    
      /**  */
      pageIndex?: string;
    
  
    
      /**  */
      rowId?: string;
    
  
    
      /**  */
      jumpType?: string;
    
  
    
      /**  */
      searchCount?: string;
    
  
    
      /**  */
      orderObjects?: string;
    
  
}

export interface NewAdminSupplierPageData {
  
    
      // T
      list?: NewAdminSupplierPageItem[];
    
      // 
      totalNumber?: number;
    
      // 
      totalPage?: number;
    
      // 
      rowId?: string;
    
      // 
      hasNext?: boolean;
    
      // 
      pageSize?: number;
    
      // 
      pageIndex?: number;
    
  
}

// 18906 分页查询供应商（筛选框）

  export const useGetNewAdminSupplierPage = () => {
    let isMount = true;
    const [isNewAdminSupplierPageLoading, setIsNewAdminSupplierPageLoading] = useState(false);
    const [dataNewAdminSupplierPage, setDataNewAdminSupplierPage] = useState<NewAdminSupplierPageData>({} as NewAdminSupplierPageData);
    const getNewAdminSupplierPageData = async(params: NewAdminSupplierPageInput, config?: AxiosRequestConfig): Promise<NewAdminSupplierPageData> => {
      if(isMount){
        setIsNewAdminSupplierPageLoading(true);
      }
      
      try{
        
          
          const res: any = await Request.get<NewAdminSupplierPageData>(`${basePath}/new/admin/supplier/page?${querystring.stringify(params as unknown as ParsedUrlQueryInput)}`, config);
          
          
          
          if(isMount){
            setDataNewAdminSupplierPage(res || {});
            setIsNewAdminSupplierPageLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsNewAdminSupplierPageLoading(false);
        }
        return false as unknown as NewAdminSupplierPageData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isNewAdminSupplierPageLoading,
      dataNewAdminSupplierPage,
      getNewAdminSupplierPageData,
    }
  }

