import { YFilterState, FilterAction } from './filter.interface';

export const initState: YFilterState = {
  isLoading: true, // 默认开始请求数据
  error: {
    isError: false,
    message: null,
  },
  isReset: false,
  data: {},
};

export const reducer = (
  state: YFilterState,
  action: FilterAction,
): YFilterState => {
  switch (action.type) {
    case 'fetch_api_data':
      return {
        ...state,
        ...action.payload.data,
      };
    default:
      return state;
  }
};
