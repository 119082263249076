import * as R from "ramda";
import {trimAll} from '../format/trimAll';

export const split = (separator: string, data: string): string[] => {
  if(R.isEmpty(data) || R.isNil(data)) {
    return [];
  }
  return R.pipe(
    R.split(separator),
    trimAll
  )(data);
}