import React from 'react';
import { Upload, Button } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import { YIcon } from '../../icon';
import { imgTypeList } from '../common/utils';
import { UploadButtonProps } from './interface';
import { TipsStyle } from './styles';
import { useModel } from './useModel';


export const UploadButton: React.FC<UploadButtonProps> = (props) => {
  const { 
    uploadType, disabled, fileList, multiple, originalProps = {},
    quantity, maxSize, errMessage, buttonText,
    getRef
  } = props;
  const { data, beforeUpload, customRequest } = useModel(props);
  const myUploadProps: UploadProps = {
    ...originalProps,
    disabled,
    name: "file",
    listType: uploadType !== 'uploadPicture' ? 'text' : 'picture-card',
    fileList,
    multiple,
    accept: data.accept,
    beforeUpload,
    showUploadList: false,
    customRequest: (options: any) => customRequest(options.file),
    className: uploadType !== 'uploadPicture' ? 'YUpload-text-upload-wrap' : '',
  }

  const Tip = () => {
    const FileTypeList = data.accept?.split(/,|image\//).filter(item => item.trim() && imgTypeList.indexOf(item.toLocaleLowerCase()) === -1) || [];
    const baseTipTest = FileTypeList.length > 0 || uploadType !== 'uploadPicture' ? 
      `请上传文件，最多${quantity}个${maxSize ? `，最大${maxSize}M` : ''}` :
      `请上传图片，最多${quantity}张${maxSize ? `，图片最大${maxSize}M` : ''}`
    return errMessage === false ? <></> : (
      <TipsStyle className="YUpload-tip-message">
        <span>{errMessage || baseTipTest}</span>
      </TipsStyle>
    )
  }

  getRef({
    uploadingStatus: data.uploadingStatus,
    customRequest,
  })

  return (
    <>
      <Upload {...myUploadProps}>
        {fileList.length >= quantity || disabled ? null : (
          <div className="YUpload-upload-button">
            {buttonText && typeof buttonText !== 'string' ? (
              <>
                {buttonText}
                {uploadType !== 'uploadPicture' && <Tip />}
              </>
            ) : (
              uploadType !== 'uploadPicture' ? (
                <>
                  <Button type="primary" style={{ marginBottom: 5 }}>
                    {data.uploadingStatus ? '上传中..' : (data.isOssGetsignatureinfoLoading ? '获取oss配置...' : buttonText || '上传文件')}
                  </Button>
                  <Tip />
                </>
              ) : (
                <>
                  <YIcon name={data.uploadingStatus || data.isOssGetsignatureinfoLoading ? 'LoadingOutlined' : 'PlusOutlined'} />
                  <div className="ant-upload-text">{buttonText || '点击上传'}</div>
                </>
              )
            )}
          </div>
        )}
      </Upload>
      {/* 提示语 */}
      {uploadType === 'uploadPicture' && !disabled && (<Tip />)}
    </>
  )
}