
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROAUTH";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 88 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}



export interface UserUpdatepasswdbymtcodeInput {
  
    
    /** 手机号 */
    telephone?: string;
  
    
    /** 密码 */
    passWd?: string;
  
    
    /** 验证码 */
    mtCode?: string;
  
}

export interface UserUpdatepasswdbymtcodeData {
  
}

// 20088 密码重置接口

  export const usePostUserUpdatepasswdbymtcode = () => {
    let isMount = true;
    const [isUserUpdatepasswdbymtcodeLoading, setIsUserUpdatepasswdbymtcodeLoading] = useState(false);
    const [dataUserUpdatepasswdbymtcode, setDataUserUpdatepasswdbymtcode] = useState<UserUpdatepasswdbymtcodeData>({} as UserUpdatepasswdbymtcodeData);
    const postUserUpdatepasswdbymtcodeData = async(params: UserUpdatepasswdbymtcodeInput, config?: AxiosRequestConfig): Promise<UserUpdatepasswdbymtcodeData> => {
      if(isMount){
        setIsUserUpdatepasswdbymtcodeLoading(true);
      }
      
      try{
        
          
          
          const res: any = await Request.post<UserUpdatepasswdbymtcodeData>(`${basePath}/user/updatePassWdByMtCode`, params, config);
          
          
          if(isMount){
            setDataUserUpdatepasswdbymtcode(res || {});
            setIsUserUpdatepasswdbymtcodeLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsUserUpdatepasswdbymtcodeLoading(false);
        }
        return false as unknown as UserUpdatepasswdbymtcodeData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isUserUpdatepasswdbymtcodeLoading,
      dataUserUpdatepasswdbymtcode,
      postUserUpdatepasswdbymtcodeData,
    }
  }

