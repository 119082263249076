import styled from 'styled-components';

export const CropperStyle = styled.div`
  height: 400px;
  width: 100%;
  margin: 0 auto;
  div {
    height: 400px;
  }
`;
