import { useEffect } from 'react';
import * as R from 'ramda';
import { useApplicationStore } from '../application/useStore';
import { BookmarkPatch } from './bookmark.interface';

export const useBookmark = () => {
  const [, dispatch] = useApplicationStore();
  const namePage = ({ pageName, selectedMenu, openMenu }: BookmarkPatch) => {
    dispatch({
      type: 'name_page',
      payload: {
        data: {
          pageName,
          selectedMenu,
          openMenu,
        },
      },
    });
  };
  const setEachPageData = ({ pageData }: BookmarkPatch) => {
    dispatch({
      type: 'set_each_data',
      payload: {
        data: {
          pageData,
        },
      },
    });
  };
  const setAllPageData = ({ pageData }: BookmarkPatch) => {
    dispatch({
      type: 'set_all_data',
      payload: {
        data: {
          pageData,
        },
      },
    });
  };
  return {
    namePage,
    setEachPageData,
    setAllPageData,
  };
};
