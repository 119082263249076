
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROAUTH";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 88 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}



export interface UserUpdatepasswdInput {
  
    
    /** 老密码 */
    oldPasswd?: string;
  
    
    /** 新密码 */
    newPasswd?: string;
  
}

export interface UserUpdatepasswdData {
  
}

// 17320 修改密码

  export const usePostUserUpdatepasswd = () => {
    let isMount = true;
    const [isUserUpdatepasswdLoading, setIsUserUpdatepasswdLoading] = useState(false);
    const [dataUserUpdatepasswd, setDataUserUpdatepasswd] = useState<UserUpdatepasswdData>({} as UserUpdatepasswdData);
    const postUserUpdatepasswdData = async(params: UserUpdatepasswdInput, config?: AxiosRequestConfig): Promise<UserUpdatepasswdData> => {
      if(isMount){
        setIsUserUpdatepasswdLoading(true);
      }
      
      try{
        
          
          
          const res: any = await Request.post<UserUpdatepasswdData>(`${basePath}/user/updatePassWd`, params, config);
          
          
          if(isMount){
            setDataUserUpdatepasswd(res || {});
            setIsUserUpdatepasswdLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsUserUpdatepasswdLoading(false);
        }
        return false as unknown as UserUpdatepasswdData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isUserUpdatepasswdLoading,
      dataUserUpdatepasswd,
      postUserUpdatepasswdData,
    }
  }

