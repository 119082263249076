import React, { useState, useRef, useEffect } from 'react';
import { RcFile } from 'antd/lib/upload/interface';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { YModal } from '../../modal';
import { getFileType } from '../common/utils';
import { CropperProps, CropperModalProps } from './interface';
import { CropperStyle } from './style';

const CropperBody: React.FC<CropperProps> = (props) => {
  const { src, aspectRatio, gerRef, onOk, cropType } = props;
  const [initCropper, setInitCropper] = useState<Cropper>();
  const refCropper = useRef<HTMLImageElement>(null);
  
  const onCropperInit = (target: Cropper) => {
    setInitCropper(target)
  };

  const cropFinish = () => {
    const imageElement: any = refCropper?.current;
    const cropper: any = imageElement?.cropper;
    (initCropper || cropper).getCroppedCanvas().toBlob((blob: any) => {
      onOk(blob);
    }, cropType);
  };

  gerRef && gerRef({ cropFinish });
  return (
    <CropperStyle>
      <Cropper
        ref={refCropper}
        src={src}
        aspectRatio={aspectRatio}
        guides={true}
        onInitialized={onCropperInit}
      />
    </CropperStyle>
  );
}

export const CropperModal: React.FC<CropperModalProps> = (props) => {
  const { cropType, aspectRatio, uploadRef, cropFinish, getRef } = props;
  const cropperBodyRef: any = useRef();
  const isClickOk = useRef(false); // 元素写法，无用可删
  const [visible, setVisible] = useState(false); // 裁剪弹框当前展示
  const [cropSrcModalShow, setCropSrcModalShow] = useState(0); // 裁剪弹框当前展示
  const [cropSrc, setCropSrc] = useState<string[]>([]); // 待裁剪地址列表
  const [uploadFileList, setUploadFileList] = useState<RcFile[]>([]);// 待上传列表
  const showNextCropFile = () => {
    if (cropSrcModalShow >= cropSrc.length) {
      setCropSrc([]);
      setCropSrcModalShow(0);
    } else {
      setCropSrcModalShow(cropSrcModalShow + 1);
    }
  };

  getRef({
    setUploadFileList,
    setCropSrc,
    setCropSrcModalShow,
  })

  // 打开预览弹窗
  useEffect(() => {
    const fileData = cropSrc[cropSrcModalShow - 1];
    const file = uploadFileList[cropSrcModalShow - 1];
    if (cropSrcModalShow && fileData && file) {
      if (getFileType(file.name) === 'image') {
        setVisible(true);
      } else {
        file && uploadRef.current.customRequest(file);
        showNextCropFile();
      }
    }
  }, [cropSrcModalShow]);
  
  return (
    <YModal
      title="查看大图"
      size="small"
      content={() => (
        <CropperBody
          gerRef={(ref: any) => cropperBodyRef.current = ref}
          src={cropSrc[cropSrcModalShow - 1] || ''}
          cropType={cropType || "image/png"}
          onOk={(blob: RcFile) => {
            if (cropFinish) cropFinish(blob);
            uploadRef.current.customRequest(blob);
          }}
          aspectRatio={aspectRatio}
          loading={!!uploadRef.current.uploadingStatus}
        />
      )}
      visible={visible}
      onOk={() => {
        isClickOk.current = true;
        cropperBodyRef.current.cropFinish();
        setVisible(false);
        showNextCropFile();
      }}
      onCancel={() => {
        if (!isClickOk.current) {
          setCropSrc([])
          setCropSrcModalShow(0);
          setVisible(false);
        }
        isClickOk.current = false;
      }}
    />
  )
}