import styled from 'styled-components';
import { YModal } from '../../modal';

export const YModalStyle = styled(YModal as any)`
  .ant-modal-body {
    height: 80vh;
    padding: 40px;

    .YUpload-filePreview-wrap {
      height: 100%;
    }
  }
`;

export const LoadingStyle = styled.div`
  min-height: 200px;
  font-size: 30px;
  color: rgba(200, 200, 200, 0.5);
  vertical-align: top;
  -webkit-transition: 0.3s color, 0.3s border;
  transition: 0.3s color, 0.3s border;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
    border: 0.2em solid currentcolor;
    border-bottom-color: transparent;
    border-radius: 50%;
    -webkit-animation: 1s loader-03 linear infinite;
    animation: 1s loader-03 linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  @-webkit-keyframes loader-03 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loader-03 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
