import {
  LayoutAction,
  LayoutState,
} from './layout.interface';

export const initialState: LayoutState = {
  useName: '',
  token: '',
  isLogin: false,
  authType: 'login'
};

export const reducer = (state: LayoutState, action: LayoutAction) => {
  switch (action.type) {
    case "login": {
      const { useName, token } = action.payload.data;
      return {
        ...state,
        useName,
        token,
        isLogin: true,
      };
    }

    case "logout": {
      return initialState;
    }

    case "change password": {
      return state;
    }

    case "switch auth type": {
      const { authType } = action.payload.data;
      return {
        ...state,
        authType
      }
    }

    default:
      return state;
  }
};
