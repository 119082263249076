
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROAUTH";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 88 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}



export interface UserScanLoginInput {
  
    
      /**  */
      code: string;
    
  
}

export interface UserScanLoginData {
  
    
      // 登录成功返回的token
      userToken?: string;
    
  
}

// 17361 钉钉扫码登陆

  export const usePostUserScanLogin = () => {
    let isMount = true;
    const [isUserScanLoginLoading, setIsUserScanLoginLoading] = useState(false);
    const [dataUserScanLogin, setDataUserScanLogin] = useState<UserScanLoginData>({} as UserScanLoginData);
    const postUserScanLoginData = async(params: UserScanLoginInput, config?: AxiosRequestConfig): Promise<UserScanLoginData> => {
      if(isMount){
        setIsUserScanLoginLoading(true);
      }
      
      try{
        
          
          
          const res: any = await Request.post<UserScanLoginData>(`${basePath}/user/scan/login`, params, config);
          
          
          if(isMount){
            setDataUserScanLogin(res || {});
            setIsUserScanLoginLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsUserScanLoginLoading(false);
        }
        return false as unknown as UserScanLoginData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isUserScanLoginLoading,
      dataUserScanLogin,
      postUserScanLoginData,
    }
  }

