import React, {Suspense} from 'react';
import { Spin } from 'antd';
import {LoadingStyle} from './styles';


export const YLoader: React.FC = ({children}) => {
  const loading = (<LoadingStyle><Spin /></LoadingStyle>)
    return (
      <Suspense fallback={loading}>
        {children}
      </Suspense>
    )
}
