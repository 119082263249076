
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROAUTH";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 88 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}



export interface UserLogoutInput {
  
}

export interface UserLogoutData {
  
}

// 11940 用户退出

  export const useGetUserLogout = () => {
    let isMount = true;
    const [isUserLogoutLoading, setIsUserLogoutLoading] = useState(false);
    const [dataUserLogout, setDataUserLogout] = useState<UserLogoutData>({} as UserLogoutData);
    const getUserLogoutData = async( config?: AxiosRequestConfig): Promise<UserLogoutData> => {
      if(isMount){
        setIsUserLogoutLoading(true);
      }
      
      try{
        
          
          const res: any = await Request.get<UserLogoutData>(`${basePath}/user/logout`, config);
          
          
          
          if(isMount){
            setDataUserLogout(res || {});
            setIsUserLogoutLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsUserLogoutLoading(false);
        }
        return false as unknown as UserLogoutData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isUserLogoutLoading,
      dataUserLogout,
      getUserLogoutData,
    }
  }

