import { Button, message, Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import React, { useState } from 'react';
import { copyValue } from './util'
import { LessContentPrpos, MoreContentPrpos, ContentPrpos, ShowMoreProps } from './modal.interface'
const info = (props: ModalFuncProps) => {
  Modal.info({
    width: 420,
    okText: '知道了',
    ...props,
  });
};
const warning = Modal.warning;
const success = Modal.success;
const error = Modal.error;
const confirm = (props: ModalFuncProps) => {
  Modal.confirm({
    width: 420,
    okText: '确认',
    cancelText: '取消',
    ...props,
  });
};
const destroyAll = Modal.destroyAll

const LessContent = (props: LessContentPrpos) => {
  const { setShowMore } = props
  return (
    <p>
      {props.lessContent}<Button type="link" onClick={() => setShowMore(true)}>更多</Button>
    </p >
  )
}
const MoreContent = (props: MoreContentPrpos) => {
  const copyText = () => {
    copyValue(props.moreContent)
    if (copyValue(props.moreContent)) {
      message.success('复制成功')
    } else {
      message.error('一键复制失败，请手动选择复制')
    }
  }
  return (
    <p>
      {props.moreContent}<Button type="link" onClick={copyText} >一键复制</Button>
    </p >
  )
}

const Content = (props: ContentPrpos) => {
  const { lessContent, moreContent } = props
  const [showMore, setShowMore] = useState(props.defaultShowMore || false)
  return <>
    {
      showMore ? <MoreContent moreContent={moreContent} /> : <LessContent setShowMore={setShowMore} lessContent={lessContent} />
    }
  </>
}

const showMore = (props: ShowMoreProps) => {
  const { lessContent, moreContent } = props
  const modalType = props.modalType || 'confirm'
  Modal[modalType]({
    width: 420,
    okText: '知道了',
    ...props,
    content: <Content defaultShowMore={false} lessContent={lessContent} moreContent={moreContent} />
  });
}
export { info, success, error, confirm, warning, destroyAll, showMore };
