
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROSUPPLIER";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 184 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}


  
    
      export interface NewResourceUploadfilesItem {
        
          // 上传url
          uploadAddress?: string;
        
          // 上传后的名字
          uploadFileName?: string;
        
          // 文件大小,单位为byte
          uploadFileSize?: number;
        
      }
    
  


export interface NewResourceUploadfilesInput {
  
    
  /** 上传文件 */
  files: any;
  
    
  /** 上传路径 */
  uploadPath: any;
  
    
  /** 文件类型，1音频2视频3其他 */
  fileType: any;
  
    
  /** 文件名 */
  fileNames: any;
  
}

export interface NewResourceUploadfilesData {
  
    
      // T
      list?: NewResourceUploadfilesItem[];
    
      // 
      totalNumber?: number;
    
      // 
      totalPage?: number;
    
      // 
      rowId?: string;
    
      // 
      hasNext?: boolean;
    
      // 
      pageSize?: number;
    
      // 
      pageIndex?: number;
    
  
}

// 19664 上传资源

  export const usePostNewResourceUploadfiles = () => {
    let isMount = true;
    const [isNewResourceUploadfilesLoading, setIsNewResourceUploadfilesLoading] = useState(false);
    const [dataNewResourceUploadfiles, setDataNewResourceUploadfiles] = useState<NewResourceUploadfilesData>({} as NewResourceUploadfilesData);
    const postNewResourceUploadfilesData = async(params: NewResourceUploadfilesInput, config?: AxiosRequestConfig): Promise<NewResourceUploadfilesData> => {
      if(isMount){
        setIsNewResourceUploadfilesLoading(true);
      }
      
      try{
        
          
          
          const res: any = await Request.post<NewResourceUploadfilesData>(`${basePath}/new/resource/uploadFiles`, params, config);
          
          
          if(isMount){
            setDataNewResourceUploadfiles(res || {});
            setIsNewResourceUploadfilesLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsNewResourceUploadfilesLoading(false);
        }
        return false as unknown as NewResourceUploadfilesData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isNewResourceUploadfilesLoading,
      dataNewResourceUploadfiles,
      postNewResourceUploadfilesData,
    }
  }

