import React, { useEffect } from 'react';
import { Empty } from 'antd';
import { FilePreviewProps, FilePreviewModelProps } from './interface';
import { aliyun } from '../common/aliyun';
import { YModalStyle, LoadingStyle } from './styles';
import { useModel } from './useModel';

const ModalBody: React.FC<FilePreviewProps> = (props) => {
  const { previewInfo, wrapId = '' } = props;
  useEffect(() => {
    const filePreviewBox = aliyun.config({
      mount: document.querySelector(`#YUpload-filePreview-wrap-${wrapId}`),
      url: previewInfo.previewURL
    })
    filePreviewBox.setToken({token: previewInfo.accessToken})
  }, []);
  
  return (
    <LoadingStyle
      className="YUpload-filePreview-wrap"
      id={`YUpload-filePreview-wrap-${wrapId}`}
    />
  );
};

const FileModal: React.FC<FilePreviewModelProps> = (props) => {
  const { hideModal, wrapId, getRef } = props;
  const {
    noData,
    previewData,
    filePreviewStatus,
    show,
    setFilePreviewStatus
  } = useModel(props);

  getRef({ show, filePreviewStatus });
  
  return hideModal ? (
    <>
      {filePreviewStatus === 3 && previewData.previewURL ? (
        <ModalBody previewInfo={previewData} wrapId={wrapId} />
      ) : (
        noData && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  ) :(
    <YModalStyle
      displayName
      visible={filePreviewStatus === 3}
      footer={null}
      content={() => (<ModalBody previewInfo={previewData} />)}
      size="large"
      onOk={() => setFilePreviewStatus(0)}
      onCancel={() => setFilePreviewStatus(0)}
    />
  );
};

export default FileModal;
export const YFilePreview = FileModal;