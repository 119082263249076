import { useState } from 'react';
import { useGetOssGetofficepreviewurl } from '../../generated/api';
import { FilePreviewModelProps } from './interface';
import { getFileType } from '../common/utils';

const supportType = [
  'doc',
  'dot',
  'wps',
  'wpt',
  'docx',
  'dotx',
  'docm',
  'dotm',
  'pptx',
  'ppt',
  'pot',
  'potx',
  'pps',
  'ppsx',
  'dps',
  'dpt',
  'pptm',
  'potm',
  'ppsm',
  'xls',
  'xlt',
  'et',
  'ett',
  'xlsx',
  'xltx',
  'csv',
  'xlsb',
  'xlsm',
  'xltm',
  'pdf',
  'lrc',
  'c',
  'cpp',
  'h',
  'asm',
  's',
  'java',
  'asp',
  'bat',
  'bas',
  'prg',
  'cmd',
  'rtf',
  'txt',
  'log',
  'xml',
  'htm',
  'html',
];

export const useModel = (props: FilePreviewModelProps) => {
  const { projectId } = props;
  const { getOssGetofficepreviewurlData } = useGetOssGetofficepreviewurl();
  const [previewData, setPreviewData] = useState({
    previewURL: '',
    accessToken: '',
  });
  const [filePreviewStatus, setFilePreviewStatus] = useState(0); // 文件预览状态
  const [noData, setNoData] = useState(false); // 文件预览状态
  // 打开新窗口
  const openPage = (previewFileUrl: string) => {
    setFilePreviewStatus(0);
    if (typeof window !== 'undefined') window.open(previewFileUrl, '_blank');
  };
  // 链接检查
  const checkUrl = (previewFileUrl: string, ossFile?: string) => {
    const fileType = getFileType(ossFile || '');
    if (ossFile && supportType.indexOf(fileType) > -1 && projectId) {
      setFilePreviewStatus(2);
      getOSSPreviewAPI(ossFile);
    } else if (previewFileUrl) {
      openPage(previewFileUrl);
    } else {
      setNoData(true);
    }
  };
  // 获取预览接口
  const getOSSPreviewAPI = async (ossFile: string) => {
    try {
      const previewInfo: any = await getOssGetofficepreviewurlData({
        projectId: projectId || '',
        ossFile,
      });
      if (
        !previewInfo ||
        !previewInfo?.previewURL ||
        !previewInfo?.accessToken
      ) {
        // openPage(previewFileUrl);
        return;
      }
      setFilePreviewStatus(3);
      setPreviewData(previewInfo);
    } catch (_error) {
      // openPage(previewFileUrl);
    }
  };
  // 显示
  const show = (url: string, ossFile: string) => {
    setFilePreviewStatus(1);
    checkUrl(url, ossFile);
  };

  return {
    noData,
    filePreviewStatus,
    previewData,
    show,
    setFilePreviewStatus,
  };
};
