import React, { useEffect } from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';
import { Empty } from 'antd';
import { Sider } from './sidebar';
import { YApplicationProps, YRouterItem } from './application.interface';
import {
  MainLayoutStyle,
  ContextLayoutStyle,
  Container,
  ContextStyle,
} from './styles';
import { useApplicationStore } from './useStore';
import { YBookmark } from '../bookmark';
import { SwitchEnv } from '../switchEnv';
import { useGetAuthUserInfo } from '../generated/api';

export const YApplication: React.FC<YApplicationProps> = ({
  children,
  config,
  temIsRelated = true,
}) => {
  const [state] = useApplicationStore();
  const { dataAuthUserInfo, getAuthUserInfoData } = useGetAuthUserInfo();
  if (typeof window === 'undefined') {
  } else {
    window.localStorage.setItem('projectId', config.projectId);
  }
  useEffect(() => {
    getAuthUserInfoData();
  }, []);

  const NotFound = () => <Empty description="抱歉，您没有权限！" />;
  const FadingRoute = ({ component, ...rest }: RouteProps) => {
    if (!component) {
      return null;
    }
    const Comp: any = component;
    return (
      <Route
        {...rest}
        render={(routeProps: RouteProps) => (
          <Comp {...routeProps} state={state} />
        )}
      />
    );
  };

  return (
    <MainLayoutStyle>
      <Sider dataSource={config.routers} config={config} />
      <ContextLayoutStyle>
        <Container>
          {((config.routers && config.routers.length) ||
            (state.menus && state.menus.length)) &&
          JSON.stringify(dataAuthUserInfo) !== '{}' ? (
            <YBookmark
              router={config.routers || state.menus}
              userId={(dataAuthUserInfo as any).userPhone}
              projectId={config.projectId}
              temIsRelated={temIsRelated}
            />
          ) : (
            ''
          )}
          {children ? (
            children
          ) : (
            <ContextStyle>
              <Switch>
                {state.menus &&
                state.menus.length &&
                state.defaultMenu &&
                state.defaultMenu.component ? (
                  <FadingRoute
                    path="/"
                    exact
                    component={state.defaultMenu.component}
                  />
                ) : null}
                {state.menus && state.menus.length
                  ? state.menus.map((item: YRouterItem) => {
                      if (item.children && item.children.length) {
                        return item.children.map((child: YRouterItem) => (
                          <FadingRoute
                            key={child.path}
                            path={`${child.path}${child.param}`}
                            component={child.component}
                          />
                        ));
                      } else {
                        return (
                          <FadingRoute
                            key={item.path}
                            path={`${item.path}${item.param}`}
                            component={item.component}
                          />
                        );
                      }
                    })
                  : null}
                {state.menus && state.menus.length ? (
                  <FadingRoute component={NotFound} />
                ) : null}
              </Switch>
              {state.isInit && (!state.menus || !state.menus?.length) ? (
                <NotFound />
              ) : null}
            </ContextStyle>
          )}
        </Container>
      </ContextLayoutStyle>
      <SwitchEnv />
    </MainLayoutStyle>
  );
};
