import {
  info,
  success,
  error,
  confirm,
  warning,
  destroyAll,
  showMore,
} from './modal.methods';
import { YImage } from './modal.image';
import { OriginModal } from './modal';
export { YModalProps, YModalImgProps } from './modal.interface';
OriginModal.info = info;
OriginModal.success = success;
OriginModal.error = error;
OriginModal.confirm = confirm;
OriginModal.warning = warning;
OriginModal.destroyAll = destroyAll;
OriginModal.showMore = showMore;
// 图片预览组件
OriginModal.Image = YImage;

export const YModal = OriginModal;
export { useModal, ModalHookDestroyAll } from './useModal';
