export const aliyun = (function (e, t) {
  'object' == typeof exports && 'undefined' != typeof module
    ? t(exports)
    : 'function' == typeof define && define.amd
    ? define(['exports'], t)
    : t(((e = e || self).aliyun = {}));
  return e.aliyun;
})({}, function (e) {
  'use strict';
  var t = function () {
    return (t =
      Object.assign ||
      function (e) {
        for (var t, n = 1, r = arguments.length; n < r; n++)
          for (var o in (t = arguments[n]))
            Object.prototype.hasOwnProperty.call(t, o) && (e[o] = t[o]);
        return e;
      }).apply(this, arguments);
  };
  function n(e, t, n, r) {
    return new (n || (n = Promise))(function (o, i) {
      function a(e) {
        try {
          s(r.next(e));
        } catch (e) {
          i(e);
        }
      }
      function c(e) {
        try {
          s(r.throw(e));
        } catch (e) {
          i(e);
        }
      }
      function s(e) {
        e.done
          ? o(e.value)
          : new n(function (t) {
              t(e.value);
            }).then(a, c);
      }
      s((r = r.apply(e, t || [])).next());
    });
  }
  function r(e, t) {
    var n,
      r,
      o,
      i,
      a = {
        label: 0,
        sent: function () {
          if (1 & o[0]) throw o[1];
          return o[1];
        },
        trys: [],
        ops: [],
      };
    return (
      (i = { next: c(0), throw: c(1), return: c(2) }),
      'function' == typeof Symbol &&
        (i[Symbol.iterator] = function () {
          return this;
        }),
      i
    );
    function c(i) {
      return function (c) {
        return (function (i) {
          if (n) throw new TypeError('Generator is already executing.');
          for (; a; )
            try {
              if (
                ((n = 1),
                r &&
                  (o =
                    2 & i[0]
                      ? r.return
                      : i[0]
                      ? r.throw || ((o = r.return) && o.call(r), 0)
                      : r.next) &&
                  !(o = o.call(r, i[1])).done)
              )
                return o;
              switch (((r = 0), o && (i = [2 & i[0], o.value]), i[0])) {
                case 0:
                case 1:
                  o = i;
                  break;
                case 4:
                  return a.label++, { value: i[1], done: !1 };
                case 5:
                  a.label++, (r = i[1]), (i = [0]);
                  continue;
                case 7:
                  (i = a.ops.pop()), a.trys.pop();
                  continue;
                default:
                  if (
                    !(o = (o = a.trys).length > 0 && o[o.length - 1]) &&
                    (6 === i[0] || 2 === i[0])
                  ) {
                    a = 0;
                    continue;
                  }
                  if (3 === i[0] && (!o || (i[1] > o[0] && i[1] < o[3]))) {
                    a.label = i[1];
                    break;
                  }
                  if (6 === i[0] && a.label < o[1]) {
                    (a.label = o[1]), (o = i);
                    break;
                  }
                  if (o && a.label < o[2]) {
                    (a.label = o[2]), a.ops.push(i);
                    break;
                  }
                  o[2] && a.ops.pop(), a.trys.pop();
                  continue;
              }
              i = t.call(e, a);
            } catch (e) {
              (i = [6, e]), (r = 0);
            } finally {
              n = o = 0;
            }
          if (5 & i[0]) throw i[1];
          return { value: i[0] ? i[1] : void 0, done: !0 };
        })([i, c]);
      };
    }
  }
  var o = (function () {
    function e() {}
    return (
      (e.add = function (t) {
        e._handleList.push(t), window.addEventListener('message', t, !1);
      }),
      (e.remove = function (t) {
        var n = e._handleList.indexOf(t);
        n >= 0 && e._handleList.splice(n, 1), window.removeEventListener('message', t, !1);
      }),
      (e.empty = function () {
        for (; e._handleList.length; )
          window.removeEventListener('message', e._handleList.shift(), !1);
      }),
      (e.parse = function (e) {
        return 'object' == typeof e ? e : e ? JSON.parse(e) : e;
      }),
      (e._handleList = []),
      e
    );
  })();
  var i,
    a,
    c,
    s = this;
  !(function (e) {
    (e.unknown = 'unknown'),
      (e.spreadsheet = 's'),
      (e.writer = 'w'),
      (e.presentation = 'p'),
      (e.pdf = 'f');
  })(i || (i = {})),
    (function (e) {
      (e.wps = 'w'), (e.et = 's'), (e.presentation = 'p'), (e.pdf = 'f');
    })(a || (a = {})),
    (function (e) {
      (e.nomal = 'nomal'), (e.simple = 'simple');
    })(c || (c = {}));
  var u,
    p,
    l =
      ((u = 0),
      function () {
        return ++u;
      }),
    d = function (e, t, n) {
      if ((void 0 === n && (n = !0), !p)) {
        var r = function (e) {
          p.style.cssText += 'height: ' + e.clientHeight + 'px; width: ' + e.clientWidth + 'px';
        }.bind(null, t);
        (p = document.createElement('iframe')).classList.add('web-office-iframe');
        var o = {
          id: 'office-iframe',
          src: e,
          scrolling: 'no',
          frameborder: '0',
          allowfullscreen: 'allowfullscreen',
          webkitallowfullscreen: 'true',
          mozallowfullscreen: 'true',
        };
        for (var i in (t
          ? ((o.style = 'width: ' + t.clientWidth + 'px; height: ' + t.clientHeight + 'px;'),
            n && window.addEventListener('resize', r))
          : ((t = document.createElement('div')).classList.add('web-office-default-container'),
            (function (e) {
              var t = document.createElement('style');
              document.head.appendChild(t);
              var n = t.sheet;
              n.insertRule(e, n.cssRules.length);
            })(
              '.web-office-default-container {position: absolute; padding: 0;  margin: 0; width: 100vw; height: 100vh; left: 0; top: 0;}',
            ),
            document.body.appendChild(t),
            (o.style = 'width: 100vw; height: 100vh;')),
        o))
          p.setAttribute(i, o[i]);
        t.appendChild(p),
          (p.destroy = function () {
            p.parentNode.removeChild(p), (p = null), window.removeEventListener('resize', r);
          });
      }
      return p;
    };
  var f = function (e) {
    d().contentWindow.postMessage(JSON.stringify(e), '*');
  };
  function v(e, t, n) {
    return new Promise(function (r) {
      var i = l(),
        a = function (e) {
          var t = o.parse(e.data);
          t.eventName === n && t.msgId === i && (r(t.data), o.remove(a));
        };
      o.add(a), f({ eventName: t, data: e, msgId: i });
    });
  }
  var b = function (e) {
      return v(e, 'wps.jssdk.api', 'wps.api.reply');
    },
    h = function (e) {
      return v(e, 'api.basic', 'api.basic.reply');
    },
    m = function (e, i, a, c) {
      return n(s, void 0, void 0, function () {
        var s, u, p, d, v, b, h, m;
        return r(this, function (y) {
          switch (y.label) {
            case 0:
              return (
                (s = l()),
                (d = new Promise(function (e, t) {
                  (u = e), (p = t);
                })),
                (v = {}),
                i.args ? [4, w(i.args)] : [3, 2]
              );
            case 1:
              (b = y.sent()), (h = b[0]), (m = b[1]), (i.args = h), (v = m), (y.label = 2);
            case 2:
              return (
                (function (e) {
                  var n = e[0],
                    r = e[1];
                  'function' == typeof (n = t({}, n)).data && (n.data = n.data());
                  r(), f(n);
                })([
                  { eventName: e, data: i, msgId: s },
                  function () {
                    var t = this,
                      i = function (l) {
                        return n(t, void 0, void 0, function () {
                          var t, n, d;
                          return r(this, function (r) {
                            switch (r.label) {
                              case 0:
                                return 'api.callback' === (t = o.parse(l.data)).eventName &&
                                  t.callbackId &&
                                  v[t.callbackId]
                                  ? [4, v[t.callbackId].apply(v, t.data.args)]
                                  : [3, 2];
                              case 1:
                                (n = r.sent()),
                                  f({
                                    eventName: 'api.callback.reply',
                                    result: n,
                                    callbackId: t.callbackId,
                                  }),
                                  (r.label = 2);
                              case 2:
                                return (
                                  t.eventName === e + '.reply' &&
                                    t.msgId === s &&
                                    (t.error
                                      ? (((d = new Error('')).stack = t.error + '\n' + a),
                                        c && c(),
                                        p(d))
                                      : u(t.result),
                                    o.remove(i)),
                                  [2]
                                );
                            }
                          });
                        });
                      };
                    return o.add(i), d;
                  },
                ]),
                [2, d]
              );
          }
        });
      });
    };
  function w(e) {
    return n(this, void 0, void 0, function () {
      var t, n, o, i, a, c, s;
      return r(this, function (r) {
        switch (r.label) {
          case 0:
            (t = {}), (n = []), (e = e.slice(0)), (r.label = 1);
          case 1:
            return e.length ? ((o = void 0), [4, e.shift()]) : [3, 3];
          case 2:
            if (
              ((i = r.sent()),
              (function (e) {
                if (!e) return !1;
                for (var t = e; null !== Object.getPrototypeOf(t); ) t = Object.getPrototypeOf(t);
                return Object.getPrototypeOf(e) === t;
              })(o))
            )
              for (a in ((o = {}), i))
                'function' == typeof (c = i[a]) && ((s = l()), (t[s] = c), (c = { callbackId: s })),
                  (o[a] = c);
            else
              'function' == typeof i ? ((s = l()), (t[s] = i), (o = { callbackId: s })) : (o = i);
            return n.push(o), [3, 1];
          case 3:
            return [2, [n, t]];
        }
      });
    });
  }
  var y = function (e, n) {
      void 0 === n && (n = !0);
      var r = t({}, e),
        o = r.headers,
        i = void 0 === o ? {} : o,
        a = r.subscriptions,
        s = void 0 === a ? {} : a,
        u = r.mode,
        p = void 0 === u ? c.nomal : u,
        l = i.backBtn,
        d = void 0 === l ? {} : l,
        f = i.shareBtn,
        v = void 0 === f ? {} : f,
        b = i.otherMenuBtn,
        h = void 0 === b ? {} : b,
        m = function (e, t) {
          e.subscribe &&
            'function' == typeof e.subscribe &&
            ((e.callback = t), (s[t] = e.subscribe), n && delete e.subscribe);
        };
      if (
        (m(d, 'wpsconfig_back_btn'),
        m(v, 'wpsconfig_share_btn'),
        m(h, 'wpsconfig_other_menu_btn'),
        h.items && Array.isArray(h.items))
      ) {
        var w = [];
        h.items.forEach(function (e, t) {
          switch ((void 0 === e && (e = {}), e.type)) {
            case 'export_img':
              (e.type = 1), (e.callback = 'export_img');
              break;
            case 'export_pdf':
              (e.type = 1), (e.callback = 'export_pdf');
              break;
            case 'save_version':
              (e.type = 1), (e.callback = 'save_version');
              break;
            case 'about_wps':
              (e.type = 1), (e.callback = 'about_wps');
              break;
            case 'split_line':
              e.type = 2;
              break;
            case 'custom':
              (e.type = 3), m(e, 'wpsconfig_other_menu_btn_' + t), w.push(e);
          }
        }),
          w.length && (k || O) && (h.items = w);
      }
      if (
        ((r.url = r.url || r.wpsUrl),
        (p === c.simple || (r.commonOptions && !1 === r.commonOptions.isShowTopArea)) &&
          r.url &&
          (r.url += '&simple &hidecmb '),
        r.debug && r.url && (r.url += '&debugger '),
        r.wordOptions && (r.wpsOptions = r.wordOptions),
        r.excelOptions && (r.etOptions = r.excelOptions),
        r.pptOptions && (r.wppOptions = r.pptOptions),
        'object' == typeof s.print)
      ) {
        var y = 'wpsconfig_print';
        'function' == typeof s.print.subscribe &&
          ((s[y] = s.print.subscribe),
          (r.print = { callback: y }),
          void 0 !== s.print.custom && (r.print.custom = s.print.custom)),
          delete s.print;
      }
      'function' == typeof s.exportPdf &&
        ((s[(y = 'wpsconfig_export_pdf')] = s.exportPdf),
        (r.exportPdf = { callback: y }),
        delete s.exportPdf);
      return r.commandBars && j(r.commandBars, !1), t({}, r, { subscriptions: s });
    },
    g = function (e) {
      void 0 === e && (e = '');
      var t = '';
      if (!t && e) {
        var n = e.toLowerCase();
        -1 !== n.indexOf('/office/s/') && (t = i.spreadsheet),
          -1 !== n.indexOf('/office/w/') && (t = i.writer),
          -1 !== n.indexOf('/office/p/') && (t = i.presentation),
          -1 !== n.indexOf('/office/f/') && (t = i.pdf);
      }
      if (!t) {
        var r = e.match(/[\?&]type=([a-z]+)/) || [];
        t = a[r[1]] || '';
      }
      return t;
    };
  function j(e, t) {
    void 0 === t && (t = !0);
    var n = e.map(function (e) {
      var t = e.attributes;
      if (!Array.isArray(t)) {
        var n = [];
        for (var r in t)
          if (t.hasOwnProperty(r)) {
            var o = { name: r, value: t[r] };
            n.push(o);
          }
        e.attributes = n;
      }
      return e;
    });
    return t && f({ eventName: 'setCommandBars', data: n }), n;
  }
  var I = window.navigator.userAgent.toLowerCase(),
    k = /Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(I),
    O = (function () {
      try {
        return -1 !== window._parent.location.search.indexOf('from=wxminiprogram');
      } catch (e) {
        return !1;
      }
    })();
  function _(e) {
    return (
      (e = e || Object.create(null)),
      {
        on: function (t, n) {
          (e[t] || (e[t] = [])).push(n);
        },
        off: function (t, n) {
          e[t] && e[t].splice(e[t].indexOf(n) >>> 0, 1);
        },
        emit: function (t, n) {
          (e[t] || []).slice().map(function (e) {
            e(n);
          }),
            (e['*'] || []).slice().map(function (e) {
              e(t, n);
            });
        },
      }
    );
  }
  var x = 0,
    E = new Set();
  function C(e) {
    return (
      ++x,
      !e &&
        (function (e) {
          E.forEach(function (t) {
            return t(e);
          });
        })(x),
      x
    );
  }
  function P(e, a) {
    var c,
      s = this,
      u = a.Events,
      p = a.Enum,
      l = a.Props,
      d = l[0],
      v = l[1],
      b = { objId: x };
    switch (
      ((function e(n, r, o) {
        r = r.slice(0);
        var i = function () {
          var i = r.shift();
          !i.alias && ~N.indexOf(i.prop) && r.push(t({}, i, { alias: i.prop + 'Async' })),
            Object.defineProperty(n, i.alias || i.prop, {
              get: function () {
                var r = this,
                  a = 1 === i.cache,
                  c = a && this['__' + i.prop + 'CacheValue'];
                if (c) return c;
                var s = new Error(''),
                  u = s.stack.split('\n').slice(2).join('\n'),
                  p = C(a),
                  l = function () {
                    for (var e, r = [], a = 0; a < arguments.length; a++) r[a] = arguments[a];
                    void 0 !== i.caller
                      ? (function e(n, r, o) {
                          r = r.slice(0);
                          var i = function () {
                            var i = r.shift();
                            !i.alias &&
                              ~N.indexOf(i.prop) &&
                              r.push(t({}, i, { alias: i.prop + 'Async' })),
                              Object.defineProperty(n, i.alias || i.prop, {
                                get: function () {
                                  var t = this,
                                    r = 1 === i.cache,
                                    a = r && this['__' + i.prop + 'CacheValue'];
                                  if (a) return a;
                                  var c = new Error(''),
                                    s = c.stack.split('\n').slice(2).join('\n'),
                                    u = C(r),
                                    p = function () {
                                      for (var t, r = [], a = 0; a < arguments.length; a++)
                                        r[a] = arguments[a];
                                      void 0 !== i.caller
                                        ? e((t = { objId: C() }), o[i.caller], o)
                                        : (t = {});
                                      return (
                                        B(
                                          p,
                                          t,
                                          'api.caller',
                                          {
                                            obj: p,
                                            parentObjId: n.objId,
                                            objId: t.objId,
                                            args: r,
                                            prop: i.prop,
                                          },
                                          s,
                                        ),
                                        t
                                      );
                                    };
                                  return (
                                    (p.objId = -1),
                                    void 0 !== i.getter && ((p.objId = u), e(p, o[i.getter], o)),
                                    B(
                                      n,
                                      p,
                                      'api.getter',
                                      { parentObjId: n.objId, objId: p.objId, prop: i.prop },
                                      s,
                                      function () {
                                        delete t['__' + i.prop + 'CacheValue'];
                                      },
                                    ),
                                    r && (this['__' + i.prop + 'CacheValue'] = p),
                                    p
                                  );
                                },
                                set: function (e) {
                                  var t = new Error(''),
                                    r = t.stack.split('\n').slice(2).join('\n');
                                  return B(
                                    n,
                                    {},
                                    'api.setter',
                                    { parentObjId: n.objId, objId: -1, prop: i.prop, value: e },
                                    r,
                                  );
                                },
                              });
                          };
                          for (; r.length; ) i();
                        })((e = { objId: C() }), o[i.caller], o)
                      : (e = {});
                    return (
                      B(
                        l,
                        e,
                        'api.caller',
                        { obj: l, parentObjId: n.objId, objId: e.objId, args: r, prop: i.prop },
                        u,
                      ),
                      e
                    );
                  };
                return (
                  (l.objId = -1),
                  void 0 !== i.getter && ((l.objId = p), e(l, o[i.getter], o)),
                  B(
                    n,
                    l,
                    'api.getter',
                    { parentObjId: n.objId, objId: l.objId, prop: i.prop },
                    u,
                    function () {
                      delete r['__' + i.prop + 'CacheValue'];
                    },
                  ),
                  a && (this['__' + i.prop + 'CacheValue'] = l),
                  l
                );
              },
              set: function (e) {
                var t = new Error(''),
                  r = t.stack.split('\n').slice(2).join('\n');
                return B(
                  n,
                  {},
                  'api.setter',
                  { parentObjId: n.objId, objId: -1, prop: i.prop, value: e },
                  r,
                );
              },
            });
        };
        for (; r.length; ) i();
      })(b, d, v),
      (b.Events = u),
      (b.Enum = p),
      (e.Enum = b.Enum),
      (e.Events = b.Events),
      g(e.url))
    ) {
      case i.writer:
        e.WordApplication = e.WpsApplication = function () {
          return b;
        };
        break;
      case i.spreadsheet:
        e.ExcelApplication = e.EtApplication = function () {
          return b;
        };
        break;
      case i.presentation:
        e.PPTApplication = e.WppApplication = function () {
          return b;
        };
        break;
      case i.pdf:
        e.PDFApplication = function () {
          return b;
        };
    }
    (e.Application = b),
      (e.Free = function (e) {
        return m('api.free', { objId: e }, '');
      }),
      (e.Stack = b.Stack =
        ((c = function (t) {
          return e.Free(t);
        }),
        function () {
          var e = [],
            t = function (t) {
              e.push(t);
            };
          return (
            E.add(t),
            {
              End: function () {
                c(e), E.delete(t);
              },
            }
          );
        }));
    var h = {};
    o.add(function (e) {
      return n(s, void 0, void 0, function () {
        var t, n, i, a, c;
        return r(this, function (r) {
          switch (r.label) {
            case 0:
              return 'api.event' === (t = o.parse(e.data)).eventName && t.data
                ? ((n = t.data),
                  (i = n.eventName),
                  (a = n.data),
                  n.objIds,
                  (c = h[i]) ? [4, c(a)] : [3, 2])
                : [3, 2];
            case 1:
              r.sent(), (r.label = 2);
            case 2:
              return [2];
          }
        });
      });
    }),
      (b.Sub = {}),
      Object.values(u).forEach(function (e) {
        Object.defineProperty(b.Sub, e, {
          set: function (t) {
            (h[e] = t),
              f({
                eventName: 'api.event.register',
                data: { eventName: e, register: !!t, objId: ++x },
              });
          },
        });
      });
  }
  var A,
    N = [
      'ExportAsFixedFormat',
      'GetOperatorsInfo',
      'ImportDataIntoFields',
      'ReplaceText',
      'ReplaceBookmark',
      'GetBookmarkText',
      'GetComments',
    ];
  function S(e, n, r) {
    n = n.slice(0);
    var o = function () {
      var o = n.shift();
      if (!o.alias && ~N.indexOf(o.prop)) {
        n.push(t({}, o, { alias: o.prop + 'Async' }));
      }
      Object.defineProperty(e, o.alias || o.prop, {
        get: function () {
          var t = this;
          var n = o.cache === 1;
          var i = n && this['__' + o.prop + 'CacheValue'];
          if (!i) {
            var a = new Error('');
            var c = a.stack.split('\n').slice(2).join('\n');
            var s = C(n);
            var u = function () {
              for (var t = [], n = 0, i; n < arguments.length; n++) {
                t[n] = arguments[n];
              }
              if (o.caller !== undefined) {
                i = { objId: C() };
                S(i, r[o.caller], r);
              } else {
                i = {};
              }
              return (
                B(
                  p,
                  i,
                  'api.caller',
                  { obj: p, parentObjId: e.objId, objId: i.objId, args: t, prop: o.prop },
                  c,
                ),
                i
              );
            };
            var p = u;
            p.objId = -1;
            if (o.getter !== undefined) {
              p.objId = s;
              S(p, r[o.getter], r);
            }
            B(
              e,
              p,
              'api.getter',
              { parentObjId: e.objId, objId: p.objId, prop: o.prop },
              c,
              function () {
                delete t['__' + o.prop + 'CacheValue'];
              },
            );
            if (n) {
              this['__' + o.prop + 'CacheValue'] = p;
            }
            return p;
          } else {
            return i;
          }
        },
        set: function (t) {
          var n = new Error('');
          var r = n.stack.split('\n').slice(2).join('\n');
          return B(
            e,
            {},
            'api.setter',
            { parentObjId: e.objId, objId: -1, prop: o.prop, value: t },
            r,
          );
        },
      });
    };
    while (n.length) {
      o();
    }
  }
  function B(e, t, n, r, o, i) {
    var a = Promise.resolve(e)
      .catch(function () {})
      .then(function () {
        return m(n, r, o, i);
      });
    (t.then = function (e, t) {
      return a.then(e, t);
    }),
      (t.catch = function (e) {
        return a.catch(e);
      }),
      (t.Destroy = function () {
        return m('api.free', { objId: t.objId }, '');
      });
  }
  var L = 'fileOpen',
    T = 'tabSwitch',
    V = 'fileSaved',
    D = 'error',
    W = function (e, t) {
      void 0 === e && (e = {});
      o.add(function (n) {
        var r = o.parse(n.data),
          i = r.eventName,
          a = void 0 === i ? '' : i,
          c = r.data,
          s = void 0 === c ? null : c,
          u = r.url,
          p = void 0 === u ? null : u;
        -1 === ['wps.jssdk.api'].indexOf(a) &&
          ('ready' === a
            ? (f({ eventName: 'setConfig', data: e }),
              A.tokenData && A.setToken(A.tokenData),
              (A.iframeReady = !0))
            : 'error' === a
            ? A.emit(D, s)
            : 'open.result' === a
            ? A.emit(L, s)
            : 'file.saved' === a
            ? A.emit(V, s)
            : 'tab.switch' === a
            ? A.emit(T, s)
            : 'api.scroll' === a && window.scrollTo(s.x, s.y),
          'function' == typeof t[a] && t[a](A, p || s));
      });
    };
  function F(e) {
    return new Promise(function (t) {
      var n = function (r) {
        var i = o.parse(r.data),
          a = i.eventName,
          c = i.data;
        a === e && ('api.ready' === a && P(A, c), t(), o.remove(n));
      };
      o.add(n);
    });
  }
  function R(e) {
    void 0 === e && (e = {}), A && A.destroy();
    try {
      var i = y(e),
        a = i.subscriptions,
        c = void 0 === a ? {} : a,
        s = i.mount,
        u = void 0 === s ? null : s,
        p = i.url,
        l = d(p, u),
        v = F('ready'),
        m = F('api.ready');
      return (
        delete i.mount,
        p && delete i.url,
        delete i.subscriptions,
        (A = t(
          {},
          (A = {
            url: p,
            version: '1.1.2',
            iframe: l,
            iframeReady: !1,
            tokenData: null,
            commandBars: null,
            tabs: {
              getTabs: function () {
                return n(this, void 0, void 0, function () {
                  return r(this, function (e) {
                    switch (e.label) {
                      case 0:
                        return [4, v];
                      case 1:
                        return e.sent(), [2, h({ api: 'tab.getTabs' })];
                    }
                  });
                });
              },
              switchTab: function (e) {
                return n(this, void 0, void 0, function () {
                  return r(this, function (t) {
                    switch (t.label) {
                      case 0:
                        return [4, v];
                      case 1:
                        return t.sent(), [2, h({ api: 'tab.switchTab', args: { tabKey: e } })];
                    }
                  });
                });
              },
            },
            setCooperUserColor: function (e) {
              return n(this, void 0, void 0, function () {
                return r(this, function (t) {
                  switch (t.label) {
                    case 0:
                      return [4, v];
                    case 1:
                      return t.sent(), [2, h({ api: 'setCooperUserColor', args: e })];
                  }
                });
              });
            },
            setToken: function (e) {
              return n(this, void 0, void 0, function () {
                return r(this, function (t) {
                  switch (t.label) {
                    case 0:
                      return [4, v];
                    case 1:
                      return (
                        t.sent(), (A.tokenData = e), f({ eventName: 'setToken', data: e }), [2]
                      );
                  }
                });
              });
            },
            ready: function () {
              return m;
            },
            destroy: function () {
              l.destroy(), o.empty(), (A = null), (E = new Set()), (x = 0);
            },
            save: function () {
              return n(this, void 0, void 0, function () {
                return r(this, function (e) {
                  switch (e.label) {
                    case 0:
                      return [4, v];
                    case 1:
                      return e.sent(), [2, b({ api: 'save' })];
                  }
                });
              });
            },
            setCommandBars: function (e) {
              return n(this, void 0, void 0, function () {
                return r(this, function (t) {
                  switch (t.label) {
                    case 0:
                      return [4, v];
                    case 1:
                      return t.sent(), j(e), [2];
                  }
                });
              });
            },
            updateConfig: function (e) {
              return (
                void 0 === e && (e = {}),
                n(this, void 0, void 0, function () {
                  return r(this, function (t) {
                    switch (t.label) {
                      case 0:
                        return [4, v];
                      case 1:
                        return (
                          t.sent(),
                          e.commandBars
                            ? (console.warn(
                                'Deprecated: `updateConfig()` 方法即将废弃，请使用`setCommandBars()`代替`updateConfig()`更新`commandBars`配置。',
                              ),
                              [2, j(e.commandBars)])
                            : [2]
                        );
                    }
                  });
                })
              );
            },
          }),
          new _.prototype.constructor(),
        )),
        W(i, c),
        A.ready(),
        A
      );
    } catch (e) {
      console.error(e);
    }
  }
  console.log('aliyun JS-SDK V1.1.2');
  var z = Object.freeze({ Listener: W, config: R });
  window.WPS = z;
  var G = R,
    J = { config: G };
  (e.config = G), (e.default = J), Object.defineProperty(e, '__esModule', { value: !0 });
});
