import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { WrapStyle } from './styles';
import { PreviewList } from '../previewList';
import { ImgModal } from '../imgModal';
import { CropperModal } from '../cropperModal';
import { UploadButton } from '../uploadButton';
import FileModal from '../fileModal';
import { SortableContainerProps } from './interface';

export const UploadContainer = SortableContainer((props: SortableContainerProps) => {
  const {
    ableCrop, aspectRatio, cropType, cropFinish,
    multiple, accept, isAccept, quantity = 1, maxSize, picWidth, picHeight, // 上传校验相关
    fileName, name = 'files',
    isOss, projectId, featureName, ossDir, securityType: baseSecurityType, expire, // oss相关
    disabled, maxShow, ossImgConfig, showFileName, buttonText, column, showFileModel = false,
    isVertical, errMessage, previewPlaceholder = '-', hidePreview,
    isDrag, fileListRef,
    originalProps,
    uploadType = 'uploadPicture',
    items: fileList,
    setFileParams, request, uploadFinish, beforeUpload, setFileList,
  } = props;
  const dealCol = isVertical ? 1 : column;
  const securityType = isOss && projectId && (featureName || ossDir) ? (baseSecurityType || 'private') : 'public';
  
  const imgModalRef: any = React.useRef();
  const fileModalRef: any = React.useRef();
  const cropperModalRef: any = React.useRef();
  const uploadRef: any = React.useRef();

  const ossAggregate = { isOss, projectId, featureName, ossDir, securityType, expire }; 
  const cropAggregate = { ableCrop, aspectRatio, cropType, cropFinish };
  const ohterAggregate = { uploadType, setFileList}

  const cropperModalProps = {uploadRef, ...cropAggregate }
  const previewListProps = {
    fileList,
    isDrag,
    disabled,
    itemProps: {
      ...ossAggregate,
      ...ohterAggregate,
      imgModalRef,
      fileModalRef,
      showFileModel,
      maxShow,
      showFileName,
      ossImgConfig: securityType === 'private' ? undefined : ossImgConfig,
      fileDisabled: disabled,
      column: dealCol
    }
  }
  const UploadButtonProps = {
    ...ossAggregate,
    ...cropAggregate,
    ...ohterAggregate,
    fileListRef,
    fileName, name,
    accept, isAccept, quantity, maxSize, picWidth, picHeight,
    setFileParams, request, uploadFinish, beforeUpload,
    uploadType, disabled, multiple, errMessage, fileList, originalProps, buttonText, cropperModalRef
  }
  
  return disabled && fileList.length == 0 ? (
    <div className="YUpload-no-data-preview">{previewPlaceholder}</div>
  ) : (
    <WrapStyle
      className="YUpload"
      disabled={!!disabled}
      hidePreview={!!hidePreview}
      uploadType={uploadType}
      column={dealCol}
    >
      {/* 预览列表 */}
      {!hidePreview && uploadType === 'uploadPicture' && (
        <PreviewList {...previewListProps} />
      )}
      {!hidePreview && uploadType !== 'uploadPicture' && (
        <div className="YUpload-list-preview-wrap">
          <PreviewList {...previewListProps} />
        </div>
      )}
      {/* 上传按钮 */}
      <UploadButton {...UploadButtonProps} getRef={ref => uploadRef.current = ref} />
      {/* 裁剪 */}
      <CropperModal {...cropperModalProps} getRef={ref => cropperModalRef.current = ref} />
      {/* 文件预览 */}
      <FileModal projectId={projectId} getRef={ref => fileModalRef.current = ref} />
      {/* 图片预览弹框 */}
      <ImgModal fileList={fileList} getRef={ref => imgModalRef.current = ref} />
    </WrapStyle>
  )
});
