// import React from "react";
import {
  useGetCategoryTreeListV1,
  CategoryTreeListV1Input,
  CategoryTreeListV1Data,
} from '../generated/api';

export const useModel = () => {
  const { getCategoryTreeListV1Data } = useGetCategoryTreeListV1();

  // 获取商品类目
  const getCategoryList = async ({
    parentNo,
  }: CategoryTreeListV1Input): Promise<CategoryTreeListV1Data> => {
    const res: any = await getCategoryTreeListV1Data({ parentNo });
    return res && res.list ? res.list : [];
  };

  return {
    getCategoryList,
  };
};
