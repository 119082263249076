import styled from 'styled-components';
import { Select } from 'antd';

export const TargetCardBoxStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  div[role='yes'] {
    border: 2px solid rgba(24, 144, 255, 1) !important;
    box-shadow: 0 10px 22px 0 rgba(24, 144, 255, 0.1) !important;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      border-width: 0 10px 10px 10px;
      border-style: solid;
      border-color: transparent rgba(24, 144, 255, 1) transparent transparent;
    }
  }
`;

export const TargetCardStyle = styled.div`
  position: relative;
  background: #fff;
  box-shadow: 0 10px 22px 0 rgba(0, 75, 154, 0.05);
  border-radius: 4px;
  border: 2px solid #f2f2f2;
  padding: 20px 20px 10px;
  margin: 0 24px 24px 0;

  &:hover {
    border: 2px solid rgba(24, 144, 255, 1);
    box-shadow: 0 10px 22px 0 rgba(24, 144, 255, 0.1);
  }
`;

export const TargetFlexStyle = styled.div`
  display: flex;
`;

export const TipsIconStyle = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: top;
  top: 0;
  line-height: 15px;
  background: rgba(216, 216, 216, 1);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50%;
  text-align: center;
  user-select: none;
  cursor: pointer;
  font-style: normal;
`;

export const TargetIcon = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: top;
  top: 0;
  cursor: pointer;
  line-height: 15px;
`;

export const TargetCardTitleStyle = styled.div`
  margin-left: 6px;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  line-height: 14px;
`;

export const TargetCardMainStyle = styled.div`
  margin-left: 6px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin-top: 8px;
  margin-bottom: 10px;
`;

export const IconWrapStyle = styled.div`
  text-align: center;
  font-size: 20px;
`;
