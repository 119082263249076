import React, { useState } from 'react';

import {
  TipsIconStyle,
  TargetCardTitleStyle,
  TargetCardMainStyle,
  TargetCardStyle,
  TargetFlexStyle,
  TargetIcon,
  TargetCardBoxStyle,
} from './styles';
import { ICustomValue, ITargetData, YTargetCardProps } from './targetCard.interface';
import { YPopover } from '../popover';

export const YTargetCard: React.FC<YTargetCardProps> = (
  {
    dataSource,
    style,
    icon,
    tipsWidth = 200,
    onClick,
    ableClick,
  },
) => {
  const [cardSelect, setCardSelect] = useState<number>(-1);

  const handleChick = (item: ITargetData, index: number) => {
    if (!ableClick) return;
    if (cardSelect === index) {
      setCardSelect(-1);
    } else {
      setCardSelect(index);
    }
    onClick && onClick(item, index);
  };

  const renderTargetCard = (item: ITargetData, index: number) => {
    return (
      <TargetCardStyle
        key={`card-${index}`}
        style={style}
        onClick={() => handleChick(item, index)}
        role={cardSelect === index ? 'yes' : 'no'}
      >
        {
          Object.keys(item).map((keys: string) => {
            let data: ICustomValue = item[keys];
            return <TargetFlexStyle>
              <YPopover content={data.remark} originProps={{ placement: 'bottomLeft' }} style={{ width: tipsWidth }}>
                {
                  icon
                    ? <TargetIcon>{icon}</TargetIcon>
                    : <TipsIconStyle>i</TipsIconStyle>
                }
              </YPopover>
              <div>
                <TargetCardTitleStyle>{data.name}</TargetCardTitleStyle>
                <TargetCardMainStyle>
                  {
                    data.render
                      ? data.render(data.value)
                      : data.value
                  }
                </TargetCardMainStyle>
              </div>
            </TargetFlexStyle>;
          })
        }
      </TargetCardStyle>
    );
  };

  return (
    <TargetCardBoxStyle>
      {dataSource && dataSource.map((item: ITargetData, index: number) =>
        renderTargetCard(item, index),
      )}
    </TargetCardBoxStyle>
  );
};
