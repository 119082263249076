import React from "react";
import { TimePicker } from "antd";
import moment from 'moment';
import CONFIG_CONST from "../filter.config";
import { RangeProps } from "../filter.interface";
import { MiddleLineStyle, DatePickerStyle } from "../styles";
import { isNullOrEmpty } from "../utils";

export const TimePickerLine: React.FC<RangeProps> = ({ placeholder, onChange, value = [], format = 'HH:mm', ...originalProps }) => {
  let placeholder1: string = CONFIG_CONST.PLACEHOLDER;
  let placeholder2: string = CONFIG_CONST.PLACEHOLDER;

  if (Array.isArray(placeholder)) {
    [placeholder1, placeholder2] = placeholder;
  }

  function isEffective(value: string | undefined): any {
    return isNullOrEmpty(value) ? undefined : value;
  }

  return (
    <DatePickerStyle>
      <TimePicker
        placeholder={placeholder1}
        format={format}
        {...originalProps}
        value={isEffective(value[0]) && moment(isEffective(value[0]), format)}
        onChange={(time) => {
          if (typeof onChange === 'function') {
            if (isEffective(value[1])) {
              onChange([time, moment(isEffective(value[1]), format)])
            } else {
              onChange([time, undefined])
            }
          }
        }}
      />
      <MiddleLineStyle><span>-</span></MiddleLineStyle>
      <TimePicker
        placeholder={placeholder2}
        format={format}
        {...originalProps}
        value={isEffective(value[1]) && moment(isEffective(value[1]), format)}
        onChange={(time) => {
          if (typeof onChange === 'function') {
            if (isEffective(value[0])) {
              onChange([moment(isEffective(value[1]), format), time])
            } else {
              onChange([undefined, time])
            }
          }
        }}
      />
    </DatePickerStyle>
  );
};
