import React from 'react';
import { Menu, Button} from 'antd';
import {SwitchEnvStyled} from './styles';
import {configs} from '../generated/config';



export const SwitchEnv = () => {
  let projectId = '';
  if(typeof window === 'undefined'){

  } else {
    projectId = window.localStorage.getItem('projectId') || '';
  }
  
  const envs = Object.keys(configs[projectId] || {});
  const changeEnv = (params: any) => {
    const env = params.key;
    if(typeof window === 'undefined'){

    } else {
      window.localStorage.setItem('env', env);
      window.location.reload();
    }
    
  }
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  const menu = (
    <Menu onClick={changeEnv}>
      {
        envs.map((env) => (
        <Menu.Item key={env}>
          {env}
        </Menu.Item>
        ))
      }
    </Menu>
  );
  if(process.env && process.env.REACT_APP_ENV !== 'prod') {
    return (
      <SwitchEnvStyled overlay={menu} placement="topRight">
        <Button>当前环境：{env}</Button>
      </SwitchEnvStyled>
    )
  } else {
    return null;
  }
  
}