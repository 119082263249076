
import { useState,  useEffect } from 'react';

import { AxiosRequestConfig } from "axios";
import Request from '@ywfe/request';
// @ts-ignore
import * as querystring from 'querystring';
// @ts-ignore
import { ParsedUrlQueryInput } from 'querystring';
// @ts-ignore
import * as R from 'ramda';
import {configs} from './config';


  const projectId = "PROAUTH";
  let env;
  if(typeof window === 'undefined'){
    env = (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  } else {
    env = window.localStorage.getItem('env') || (process.env.REACT_APP_ENV === 'prod' ? 'prod': process.env.REACT_APP_ENV) || 'prod';
  }
  
  const basePath = configs[projectId][env].API_BASE_URL + `${env === 'mock' ? '/' + 88 : ''}`;
  

// @ts-ignore
const parseParams = <T>(params: T) => {
  return R.values<any, any>(params).join('/');
}


  
    
      export interface ProjectListItem {
        
          // 
          projectId: string;
        
          // 
          projectName: string;
        
          // 
          roleNames: string[];
        
          // 是否有孩子
          hasChild: boolean;
        
      }
    
  


export interface ProjectListInput {
  
    
      /**  */
      userProjectRelType: string;
    
  
}

export interface ProjectListData {
  
    
      // 项目列表
      projectList: ProjectListItem[];
    
      // 模块列表
      moduleList: ProjectListItem[];
    
  
}

// 12425 首页项目模块列表

  export const useGetProjectList = () => {
    let isMount = true;
    const [isProjectListLoading, setIsProjectListLoading] = useState(false);
    const [dataProjectList, setDataProjectList] = useState<ProjectListData>({} as ProjectListData);
    const getProjectListData = async(params: ProjectListInput, config?: AxiosRequestConfig): Promise<ProjectListData> => {
      if(isMount){
        setIsProjectListLoading(true);
      }
      
      try{
        
          
          const res: any = await Request.get<ProjectListData>(`${basePath}/project/list?${querystring.stringify(params as unknown as ParsedUrlQueryInput)}`, config);
          
          
          
          if(isMount){
            setDataProjectList(res || {});
            setIsProjectListLoading(false);
          }
          return res;
      } catch(error){
        console.log(error);
        if(isMount){
          setIsProjectListLoading(false);
        }
        return false as unknown as ProjectListData;
      }
      
    }

    useEffect(() => {
      return () => {
        isMount = false;
      };
    }, []);
  
    return {
      isProjectListLoading,
      dataProjectList,
      getProjectListData,
    }
  }

