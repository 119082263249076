import { useState, useCallback, useEffect } from "react";

function useWidth():[number, any] {
  const [width, setWidth] = useState(0);

  const onResize = useCallback(() => {
    if(typeof window !== 'undefined'){
      setWidth(window.document.documentElement.clientWidth);
    }
  }, []);

  useEffect(() => {
    if(typeof window !== 'undefined'){
      window.addEventListener("resize", onResize, false);
      setWidth(window.document.documentElement.clientWidth);
      return () => {
        window.removeEventListener("resize", onResize, false);
      };
    }
  }, []);

  return [width, setWidth];
}

export {
  useWidth
};
