import React from "react";
import { Input } from "antd";
import CONFIG_CONST from "../filter.config";
import { RangeProps } from "../filter.interface";
import { MiddleLineStyle, RangeInputStyle } from "../styles";
import { isNullOrEmpty } from "../utils";

export const Range: React.FC<RangeProps> = ({ placeholder, onChange, value = [], ...originalProps }) => {
  let placeholder1: string = CONFIG_CONST.PLACEHOLDER;
  let placeholder2: string = CONFIG_CONST.PLACEHOLDER;

  if (Array.isArray(placeholder)) {
    [placeholder1, placeholder2] = placeholder;
  }

  function isEffective(value: string | number | undefined): string | number | undefined {
    return isNullOrEmpty(value) ? undefined : value;
  }

  return (
    <>
      <Input
        placeholder={placeholder1}
        value={isEffective(value[0])}
        style={{width: "calc(50% - 6px)"}}
        {...originalProps}
        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { typeof onChange === "function" && onChange([e.target.value, isEffective(value[1])]); }}
      />
      <MiddleLineStyle><span>-</span></MiddleLineStyle>
      <Input
        placeholder={placeholder2}
        style={{width: "calc(50% - 6px)"}}
        value={isEffective(value[1])}
        {...originalProps}
        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { typeof onChange === "function" && onChange([isEffective(value[0]), e.target.value]); }}
      />
    </>
  );
};
