import React, { Component, createContext, useContext } from "react";
import { createSubscriptionHook } from "@ywfe/hook-subscription";
import { initialState, reducer } from "./layout.reducer";
import {
  YLayoutProps,
  YLayoutState,
  LayoutContextProps,
} from "./layout.interface";
import {LayoutContext} from './layout.context';
import { Layout } from "./layout";

export class YLayout extends Component<YLayoutProps, YLayoutState> {
  constructor(props: YLayoutProps) {
    super(props);
    this.state = {
      store: createSubscriptionHook(reducer, initialState)
    };
  }

  render() {
    const configObject = {
      store: this.state.store,
    };

    const {children, config, stage} = this.props;

    const childs = React.Children.map(children, (child) => {
      return React.cloneElement(child as React.FunctionComponentElement<LayoutContextProps>, { store: this.state.store });
    });

    return (
      <LayoutContext.Provider value={configObject}>
        <Layout config={config} stage={stage}>
          {childs}
        </Layout>
      </LayoutContext.Provider>
    );
  }
}
