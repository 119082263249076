import React from 'react';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import { UploadContainer } from './container';
import { YUploadProps, ChangeType } from './upload.interface';
import { useModel } from './upload.useModel';

const DragUploadContainer: React.FC<YUploadProps> = (props) => {
  const { uploadType } = props;
  const { fileListRef, fileList, setFileList } = useModel(props);

  return (
    <UploadContainer
      {...props}
      fileListRef={fileListRef}
      uploadType={uploadType || "uploadPicture"}
      distance={1}
      axis="xy"
      helperClass="SortableHelper"
      setFileList={setFileList}
      items={fileList}
      onSortEnd={({ oldIndex, newIndex }: SortEnd) => {
        setFileList(arrayMove(fileList, oldIndex, newIndex), [], ChangeType.DRAG);
      }}
    />
  )
}

export class YUpload extends React.Component<YUploadProps> {
  // 缩略图模式
  static Thumbnail (props: YUploadProps) {
    return <DragUploadContainer {...props} uploadType="thumbnail" />
  }
  // 列表模式
  static File (props: YUploadProps) {
    return <DragUploadContainer {...props} uploadType="uploadFile" />
  }
  // 默认大图模式
  render () {
    return (
      <DragUploadContainer {...this.props} />
    );
  }
}
