import { ValueType, CheckRuleParams } from './checkbox.interface';

export const useCheckboox = (
  checkItemList: Array<CheckRuleParams>,
  callback: (checkList: Array<boolean>) => void,
) => {
  // 用于控制其他表单控件是否展示
  const show = (changedValue: Array<ValueType>) => {
    const checkResultList: Array<boolean> = checkItemList.map((item) => {
      // 自定义检查
      if (item.check) {
        return item.check(changedValue);
      }
      switch (item.rule) {
        case 'containOr': // 包含 - 或的关系
          if (typeof item.value === 'object') {
            return !item.value.every(
              (valItem) => !changedValue.includes(valItem),
            );
          } else {
            return changedValue.includes(item.value);
          }
        case 'notContainOr': // 不包含 - 或的关系
          if (typeof item.value === 'object') {
            return (
              item.value.filter((valItem) => !changedValue.includes(valItem))
                .length === item.value.length
            );
          } else {
            return !changedValue.includes(item.value);
          }
        case 'contain': // 包含 - 且的关系
          if (typeof item.value === 'object') {
            return item.value.every((valItem) =>
              changedValue.includes(valItem),
            );
          } else {
            return changedValue.includes(item.value);
          }
        case 'notContain': // 不包含 - 且的关系
          if (typeof item.value === 'object') {
            return item.value.every(
              (valItem) => !changedValue.includes(valItem),
            );
          } else {
            return !changedValue.includes(item.value);
          }
        case 'notEqual': // 不等于
          if (typeof item.value === 'object') {
            return (
              changedValue.length !== item.value.length ||
              changedValue.sort().toString() !== item.value.sort().toString()
            );
          } else {
            return (
              (changedValue.length === 1 && changedValue[0] !== item.value) ||
              changedValue.length !== 1
            );
          }
        case 'equal':
        default:
          // 等于
          if (typeof item.value === 'object') {
            return (
              changedValue.length === item.value.length &&
              changedValue.sort().toString() === item.value.sort().toString()
            );
          } else {
            return changedValue.length === 1 && changedValue[0] === item.value;
          }
      }
    });

    callback(checkResultList);
  };

  return {
    show,
  };
};
