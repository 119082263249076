import React, { useEffect, useState } from 'react';
import { Layout as AntLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { SiderProps, YRouterItem } from './application.interface';
import { useApplicationStore } from './useStore';
import { useSidebar } from './useSidebar';
import { YIcon } from '../icon';
import { MenuStyle, SideBarStyle } from './styles';

const { SubMenu } = Menu;

export const Sider: React.FC<SiderProps> = ({ dataSource, config }) => {
  const [state] = useApplicationStore();
  const useLocationFn = useLocation();
  
  const { getMenu, isAuthUserMenuLoading } = useSidebar(config);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { pathname } = useLocationFn;
      getMenu(pathname);
    }
  }, [isAuthUserMenuLoading]);

  const list = dataSource || state.menus;

  useEffect(() => {
    if (state.defaultMenu?.url) {
      if (typeof window !== 'undefined') {
        window.location.href = state.defaultMenu?.url;
      }
    }
  }, [state.defaultMenu]);

  const [selectedKeys, setSelectedKeys] = useState(
    state.selectedMenu?.length === 0
      ? state.defaultSelectedMenus
      : state.selectedMenu,
  );
  const [openKeys, setOpenKeys] = useState(
    state.selectedMenu?.length === 0 ? state.defaultOpenMenus : state.openMenu,
  );
  useEffect(() => {
    setSelectedKeys(state.selectedMenu);
    setOpenKeys(state.openMenu);
  }, [state.selectedMenu, state.openMenu]);

  const selectMenu = ({ selectedKeys }: any) => {
    setSelectedKeys(selectedKeys);
  };
  const openMenu = (openKeys: string[]) => {
    if (openKeys.length === 2) {
      setOpenKeys([openKeys[1]]);
    } else {
      setOpenKeys(openKeys);
    }
  };

  return (
    <SideBarStyle>
      {list && list.length ? (
        <MenuStyle
          mode="inline"
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onSelect={selectMenu}
          //@ts-ignore
          onOpenChange={openMenu}
        >
          {list.map((item: YRouterItem) => {
            const icon = item.meta.icon && <YIcon name={item.meta.icon} />;
            if (item.children && item.children?.length && !item.hidden) {
              return (
                <SubMenu key={item.name} icon={icon} title={item.meta?.title}>
                  {item.children?.length &&
                    item.children.map((child) => {
                      if (!child.hidden) {
                        return (
                          <Menu.Item key={child.name}>
                            {child.url ? (
                              <a href={child.url} target="_self">
                                {child.meta?.title}
                              </a>
                            ) : (
                              <Link to={`${child.path}${child.param}`}>
                                {child.meta?.title}
                              </Link>
                            )}
                          </Menu.Item>
                        );
                      } else {
                        return null;
                      }
                    })}
                </SubMenu>
              );
            }
            if (!item.hidden) {
              return (
                <Menu.Item key={item.name} icon={icon}>
                  {item.url ? (
                    <a href={item.url} target="_self">
                      {item.meta?.title}
                    </a>
                  ) : (
                    <Link to={`${item.path}${item.param}`}>
                      {item.meta?.title}
                    </Link>
                  )}
                </Menu.Item>
              );
            } else {
              return null;
            }
          })}
        </MenuStyle>
      ) : (
        ''
      )}
    </SideBarStyle>
  );
};
