import React from 'react';
import ReactDOM from 'react-dom';
import {
  YModalImgOutContainerProps,
  YModalImgOutContainerState,
} from '../modal.interface';

class OutContainer extends React.Component<
  YModalImgOutContainerProps,
  YModalImgOutContainerState
> {
  node!: Element;
  constructor(props: YModalImgOutContainerProps) {
    super(props);
    this.state = {
      visible: props.visible,
    };
    if (typeof window !== 'undefined') {
      this.node = document.createElement('div');
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (typeof window !== 'undefined') {
      if (nextProps.visible) {
        document.body.appendChild(this.node);
      } else {
        this.node.parentNode && this.node.parentNode.removeChild(this.node);
      }
    }
  }
  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.node);
  }
}
export default OutContainer;
