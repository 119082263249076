// filter.config.ts
const CONFIG_CONST = {
  /**
   * 自定义组件 placeholder
   */
  PLACEHOLDER: '请输入',

  /**
   * antd 自适应时屏幕宽度的值
   */
  SCREEN_WIDTH_992: 992,

  /**
   * antd 自适应时屏幕宽度的值
   */
  SCREEN_WIDTH_768: 768,

  /**
   * 处理 DatePicker，RangePicker 的前缀标示
   */
  DATE_COMPONENT_MARK: '__@__',

  /**
   * 外部自己定义的组件，apiParam 添加一个标识
   */
  EXTERNAL_RENDER_MARK: '_@_@_@_',

  /**
   * 对于最大个数时，搜索框个数默认数目
   */
  SHOW_MAX_SEARCH_NUM: 5,

  /**
   * 如果是翻页，默认是第一页
   */
  PAGE_INDEX: 1,

  /**
   * 如果是翻页，默认是第一页
   */
  PAGE_SIZE: 10,

  /**
   * 第几页，默认的key值
   */
  API_PAGE_INDEX: 'pageIndex',

  /**
   * 每页多少个，默认的key值
   */
  API_PAGE_SIZE: 'pageSize',

  /**
   * 当label是字符串时，标题默认 宽度
   */
  LABEL_WIDTH: 72,
};

export default CONFIG_CONST;
