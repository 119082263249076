import * as R from "ramda";

export const trimAll = <T extends string | string[]>(data: T): T => {
  if(R.isNil(data) || R.isEmpty(data)) {
    return data;
  }
  const type = R.type(data);
  switch(type){
    case 'String':{
      return R.trim(data as string) as T;
    }
    case 'Array': {
      return R.filter(R.complement(R.isEmpty))(data as string[]) as T;
    }
    default: {
      return data;
    }
  }
}
