import styled from 'styled-components';
// const __THEM_PATH_ = '../styles/antd-theme'

export const FilterOperationStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0;
    margin-right: 12px;
  }
  button:last-child {
    margin-right: 0;
  }
  button {
    svg {
      vertical-align: initial;
    }
  }
`;
export const MiddleLineStyle = styled.span`
  color: #969fa9;
  width: 12px;
  display: inline-block;
  text-align: center;
`;
export const RangeInputStyle = styled.span`
  input {
    width: calc(50% - 6px);
  }
`;
export const DatePickerStyle = styled.div`
  // 中间线宽度是 12，
  > div {
    /* max-width: 43.5%; */
    width: calc(50% - 6px);
  }
`;
export const TabsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  div[role='tabs-item'] {
    &:nth-of-type(1) {
      display: flex;
      div[role='button'] {
        color: #333;
        font-size: 14px;
        padding: 8px 16px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: all 0.3s linear;
        &:hover {
          color: #000;
          border-bottom: 2px solid #1890ff;
          transition: all 0.3s linear;
        }
      }
      div[role-active='yes'] {
        color: #000;
        border-bottom: 2px solid #1890ff;
        &:hover {
          color: #000;
          border-bottom: 2px solid #1890ff;
          transition: all 0.3s linear;
        }
      }
      div[role-disabled='yes'] {
        user-select: none;
        color: rgba(0, 0, 0, 0.25);
        cursor: not-allowed;
        &:hover {
          color: rgba(0, 0, 0, 0.25);
          border-bottom: 2px solid transparent;
        }
      }
    }
    &:nth-of-type(2) {
      margin-top: 4px;
    }
  }
`;
export const CustomStyle = styled.div`
  div[role='isCustom'] {
    > div {
      &:last-child {
        max-width: calc(
          100% - ${(props: { width: number }) => props.width + 'px'}
        );
      }
    }
  }
`;
