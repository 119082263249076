import styled from 'styled-components';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export const TabsStyle = styled(Tabs)`
  margin-bottom: 0;
  overflow: visible;
`;

export const TabPaneStyle = styled(TabPane)`
  position: relative;
`;
